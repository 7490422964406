import { TextField } from "@mui/material";

interface TextFieldOptionalProps {
  label: string
  value: string
  baseValue: string
  placeholder?: string
  onChange: (newValue: string) => void
}

export function getColor(currentValue: string, originalValue: string) {
  return currentValue === originalValue ? "success" : "secondary";
}

export function TextFieldOptional(props: TextFieldOptionalProps) {
  return (
    <TextField
      fullWidth
      size="small"
      label={props.label}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(event) => props.onChange(event.target.value)}
    />
  )
}