import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Backdrop, Button, ButtonGroup, CircularProgress, Skeleton, Stack } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';
import { RegularDayButton } from './../studentDay/RegularDayButton';
import { SchoolDay, TeacherDayStatus } from './Dtos';
import { TeacherSwitchList } from './TeacherSwitchList';

interface TeacherDayProps {
  urlPath: string,
  selectedYear: string
  selectedStatus: string,
  deselectedStatus: string,
  logout: () => void
}

export function TeacherDayComponent(props: TeacherDayProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [activeTerm, setActiveTerm] = useState<string>("Term1")
  const [termDays, setTermDays] = useState<Map<string, SchoolDay[]>>(new Map<string, SchoolDay[]>())
  const [activeDay, setActiveDay] = useState<SchoolDay | undefined>(undefined)
  const [teacherDayStatuses, setTeacherDayStatuses] = useState<TeacherDayStatus[]>([])
  const [teacherDayStatusesBackup, setTeacherDayStatusesBackup] = useState<TeacherDayStatus[]>([])
  const [noOfTeachers, setNoOfTeachers] = useState(8)
  const [backdrop, setBackdrop] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  async function loadSchoolDays() {
    try {
      console.log("Fetching schoolDays for level: " + props.urlPath + " day: " + activeDay?.day)
      const dayFilter = activeDay?.day ? `&day=${activeDay.id}` : ""
      setBackdrop(true)
      const response = await fetch(`api/${props.urlPath}/volunteer/days?year=${props.selectedYear}${dayFilter}`)
      if (response.ok) {
        const allSchoolDays: SchoolDay[] = await response.json();
        const workdingTermDays = new Map<string, SchoolDay[]>()
        workdingTermDays.set("Term1", allSchoolDays.filter((schoolDay) => schoolDay.term === "Term1"))
        workdingTermDays.set("Term2", allSchoolDays.filter((schoolDay) => schoolDay.term === "Term2"))
        workdingTermDays.set("Term3", allSchoolDays.filter((schoolDay) => schoolDay.term === "Term3"))
        setTermDays(workdingTermDays)
        const activeDayReceived = allSchoolDays.find((schoolDay) => schoolDay.active);
        setActiveDay(activeDayReceived)
        activeDayReceived && setActiveTerm(activeDayReceived.term)
        console.log("ActiveDay: " + allSchoolDays.find((schoolDay) => schoolDay.active)?.day)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No schoolDays found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    loadSchoolDays();
  }, [props.urlPath, props.selectedYear, refresh])

  async function loadTeacherDayStatus(day: number) {
    try {
      setTeacherDayStatuses([]) // Clear teacherDayStatuses while loading
      console.log(`Fetching ${props.urlPath} for day: ${day}`)
      setBackdrop(true)
      const response = await fetch(`api/${props.urlPath}/volunteer?day=${day}`)
      if (response.ok) {
        const result: TeacherDayStatus[] = await response.json();
        setTeacherDayStatuses(result)
        setTeacherDayStatusesBackup(result)
        setNoOfTeachers(result.length)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No teacherDayStatus found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    if (activeDay) {
      loadTeacherDayStatus(activeDay?.id);
    }
  }, [props.urlPath, props.selectedYear, activeDay?.id, refresh])

  function handleOnTeacherDayStatusChange(teacherId: Number, isSelected: boolean): void {
    console.log("TeacherId: " + teacherId + " isSelected: " + isSelected)
    const updatedTeacherDayStatuses = teacherDayStatuses.map((teacherDayStatus) => {
      if (teacherDayStatus.teacherId === teacherId) {
        return { ...teacherDayStatus, status: isSelected ? props.selectedStatus : props.deselectedStatus }
      }
      return teacherDayStatus
    })
    setTeacherDayStatuses(updatedTeacherDayStatuses)
  }

  function cancelHandler(): void {
    setTeacherDayStatuses(teacherDayStatusesBackup)
  }

  async function saveHandler(): Promise<void> {
    try {
      console.log(`Saving ${props.urlPath} for day: ${activeDay?.day}`)
      setBackdrop(true)
      const response = await fetch(`api/${props.urlPath}/volunteer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(teacherDayStatuses)
      })
      if (response.ok) {
        console.log(`${props.urlPath} saved successfully.`)
        setRefresh(!refresh)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log(`${props.urlPath} save failed.`)
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  async function deleteHandler(): Promise<void> {
    setShowConfirmationDialog(false)
    if (activeDay) {
      try {
        console.log(`Deleting ${props.urlPath} for day: ${activeDay.day}`)
        setBackdrop(true)
        const response = await fetch(`api/${props.urlPath}/volunteer?day=${activeDay.id}`, {
          method: 'DELETE',
        })
        if (response.ok) {
          console.log(`${props.urlPath} deleted successfully.`)
          setRefresh(!refresh)
        } else if (response.status === 401) {
          props.logout()
        } else {
          console.log(`${props.urlPath} delete failed.`)
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  }

  const teachersSkeleton = [...Array(noOfTeachers)].map((_, index) => <Skeleton variant="rectangular" height={54} key={index} />)

  function onTermChange(term: string): void {
    setActiveTerm(term)
    const activeDayInTerm = termDays.get(term)?.find((schoolDay) => schoolDay.active) ?? termDays.get(term)?.[0]
    setActiveDay(activeDayInTerm)
  }

  return (
    <Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack gap={2} padding={2} height='100%'>
        <Stack direction="row" justifyContent="center">
          <ButtonGroup variant='outlined'>
            <Button color='info' variant={activeTerm === 'Term1' ? 'contained' : 'outlined'} onClick={() => onTermChange('Term1')}>Term 1</Button>
            <Button color='info' variant={activeTerm === 'Term2' ? 'contained' : 'outlined'} onClick={() => onTermChange('Term2')}>Term 2</Button>
            <Button color='info' variant={activeTerm === 'Term3' ? 'contained' : 'outlined'} onClick={() => onTermChange('Term3')}>Term 3</Button>
          </ButtonGroup>
        </Stack>
        <Stack direction="row" justifyContent="center" >
          {/* Special style to allow wrap and complete the button border */}
          <ButtonGroup
            variant='outlined'
            size='small'
            sx={{
              flexWrap: "wrap",
              justifyContent: "center",
              ".MuiButtonGroup-grouped:not(:last-of-type)": {
                borderRightColor: "info.main"
              }
            }}>
            {termDays.get(activeTerm)?.map(schoolDay => <RegularDayButton schoolDay={schoolDay} isActiveDay={activeDay?.id === schoolDay.id} key={schoolDay.id} onClick={() => setActiveDay(schoolDay)} />)}
          </ButtonGroup>
        </Stack>
        {teacherDayStatuses.length === 0 &&
          <Stack gap={2} height="100%">
            {teachersSkeleton}
          </Stack>
        }
        {activeDay && teacherDayStatuses.length > 0 &&
          <Stack height="100%">
            <TeacherSwitchList
              selectedDay={activeDay}
              selectedStatus={props.selectedStatus}
              teacherDayStatuses={teacherDayStatuses}
              onTeacherSwitchChange={handleOnTeacherDayStatusChange} />
          </Stack>
        }
      </Stack>
      {activeDay &&
        <BottomNavBar>
          <ConfirmationDialog
            open={showConfirmationDialog}
            message={`Are you sure you want to delete '${props.urlPath}' for '${activeDay.day}'?`}
            onConfirm={deleteHandler}
            onCancel={() => setShowConfirmationDialog(false)} />
          <BottomNavAction label="Reload" icon={<RestartAltIcon />} onClick={cancelHandler} />
          <BottomNavAction label="Delete" icon={<DeleteForeverIcon />} onClick={() => setShowConfirmationDialog(true)} loading={!(activeDay && activeDay.completed)} />
          <BottomNavAction label="Save" icon={<SaveIcon />} onClick={saveHandler} />
        </BottomNavBar>
      }
    </Fragment>
  );
}