import { Paper } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

interface SPaperProps {
  children: ReactNode
  elevation?: number
  sx?: CSSProperties;
}

export function SPaper({ children, elevation = 3, sx }: SPaperProps) {
  return (
    <Paper elevation={elevation} sx={{ ...({ borderRadius: 4 }), ...(sx) }} >
      {children}
    </Paper >
  );
}