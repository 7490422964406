import { TextField } from "@mui/material";
import { getColorRequired } from "./TextFieldRequired";

interface TextFieldRequiredDateProps {
  label: string
  value: string
  baseValue: string
  onChange: (newValue: string) => void
}

export function TextFieldRequiredDate(props: TextFieldRequiredDateProps) {
  return (
    <TextField
      required
      fullWidth
      type="date"
      size="small"
      label={props.label}
      value={props.value === "" ? new Date() : props.value}
      onChange={(event) => props.onChange(event.target.value)}
    />
  )
}