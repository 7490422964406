import { Collapse, Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { ExamPatternCategory } from '../../components/AppConstants';
import { SCardRowExpandText } from '../../components/styled/card/SCardRowExpandText';
import { SCardRowText } from '../../components/styled/card/SCardRowText';

interface ExamSetupCardEntryProps {
  examPatternCategories: ExamPatternCategory[]
}

export function ExamSetupCardEntry(props: ExamSetupCardEntryProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Fragment>
      <SCardRowExpandText
        label={props.examPatternCategories[0].categoryType}
        value={props.examPatternCategories.reduce((sum, category) => sum + category.categoryPercentage, 0) + "%"}
        expanded={expanded}
        handleExpandClick={() => setExpanded(!expanded)}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.examPatternCategories.map((category, index) => (
          <Stack key={index} paddingLeft={3}>
            {props.examPatternCategories[0].categoryType != category.categoryName && <SCardRowText label={"- " + category.categoryName} value={category.categoryMax} />}
            {props.examPatternCategories[0].categoryType === category.categoryName && <SCardRowText label={"- Max Marks"} value={category.categoryMax} />}
          </Stack>
        ))}
      </Collapse>
    </Fragment>
  );
}
