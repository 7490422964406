import AddIcon from '@mui/icons-material/Add';
import { Backdrop, CircularProgress, Fab, Skeleton, Stack } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { AccessLevel, UserInfo, UserType } from '../../components/AppConstants';
import { ButtonGroupControlMiddle } from '../../components/custom/ButtonGroupControlMiddle';
import { EditUser } from './EditUser';
import { UserCard } from './UserCard';

interface UsersPageProps {
  userInfo: UserInfo,
  selectedLevel: string,
  logout: () => void
}

export function UsersPage(props: UsersPageProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [backdrop, setBackdrop] = useState(false);
  const [users, setUsers] = useState<UserInfo[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserInfo[]>([])
  const [selectedUser, setSelectedUser] = useState<UserInfo | undefined>(undefined)
  const [selectedUserType, setSelectedUserType] = useState<string>("All")
  const userTypeOptions = ["All"].concat(Object.keys(UserType))

  async function loadUsers() {
    try {
      const urlToLoad = "api/users";
      console.log(urlToLoad)
      setBackdrop(true)
      const response = await fetch(urlToLoad)
      if (response.ok) {
        const result: UserInfo[] = await response.json();
        setUsers(result)
        setFilteredUsers(selectedUserType === "All" ? result : result.filter(user => user.type === selectedUserType))
        if (selectedUser) {
          const existingUserInDb = result.find((user) => user.id === selectedUser.id);
          setSelectedUser(existingUserInDb)
        }
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No library books found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    loadUsers()
  }, [refresh])

  async function onDeleteClick(userInfo: UserInfo): Promise<void> {
    try {
      setBackdrop(true)
      const response = await fetch("api/users/" + userInfo.id, { method: 'DELETE' })
      if (response.ok) {
        console.log("User deleted successfully.")
        setSelectedUser(undefined)
        setRefresh(!refresh)
        enqueueSnackbar('User deleted successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("User deletion failed.")
        enqueueSnackbar('Student deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('User deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function onUserUpdated(userInfo: UserInfo): Promise<void> {
    try {
      setBackdrop(true)
      const response = await fetch("api/users/" + userInfo.id, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userInfo)
      })
      if (response.ok) {
        console.log("User updated successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('User updated successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("User update failed.")
        enqueueSnackbar('User update failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('User update failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function onUserAdded(userInfo: UserInfo): Promise<void> {
    try {
      setBackdrop(true)
      const response = await fetch("api/users", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userInfo)
      })
      if (response.ok) {
        console.log("User added successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('User added successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Adding user failed.")
        enqueueSnackbar('Adding user failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Adding user failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  return (
    <Stack height='100%'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarProvider />
      {loading &&
        <Stack gap={1} padding={1}>
          <Skeleton variant="rectangular" height={322} />
          <Skeleton variant="rectangular" height={322} />
          <Skeleton variant="rectangular" height={322} />
        </Stack>
      }
      {!loading && !selectedUser &&
        // Show summary
        <Stack gap={2} padding={2}>
          <ButtonGroupControlMiddle value={selectedUserType} options={userTypeOptions} onChange={value => {
            setSelectedUserType(value)
            setFilteredUsers(value === "All" ? users : users.filter(user => user.type === value))
          }} />
          <Stack gap={2} paddingBottom={8}>
            {filteredUsers.map((user, index) =>
              <UserCard
                key={index}
                loggedInUser={props.userInfo}
                userInfo={user}
                index={index + 1}
                onPersonClick={userInfo => { setSelectedUser(userInfo) }} />
            )}
          </Stack>
        </Stack>
      }
      {!loading && !selectedUser && props.userInfo.userAccess === AccessLevel.ReadWrite &&
        <Fab color="secondary" sx={{ position: 'fixed', bottom: '16px', left: '50%', transform: 'translateX(-50%)' }} onClick={(_event) => {
          setSelectedUser({
            id: -1,
            name: "",
            email: "",
            tamilName: "",
            phone: "",
            type: UserType.Volunteer,
            year: [],
            level: [],
            allLevels: false,
            libraryAccess: AccessLevel.NoAccess,
            studentInfoAccess: AccessLevel.NoAccess,
            userAccess: AccessLevel.NoAccess,
            isTest: false
          })
        }}>
          <AddIcon />
        </Fab>
      }
      {!loading && selectedUser &&
        <EditUser
          userInfo={selectedUser}
          onBackClick={() => { setSelectedUser(undefined) }}
          onDeleteClick={onDeleteClick}
          onUserUpdated={user => user.id === -1 ? onUserAdded(user) : onUserUpdated(user)}
          logout={props.logout} />
      }
    </Stack>
  );
}