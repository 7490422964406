import CloseIcon from '@mui/icons-material/Close';
import { Chip, Stack } from '@mui/material';

interface GridEntryMultipleValuesProps {
  label: string
  values: string[]
}

export function GridEntryMultipleValues(props: GridEntryMultipleValuesProps) {

  return (
    <Stack direction="row" justifyContent="center" paddingTop={1}>
      {props.values.length === 0 && <Chip size='small' variant='outlined' label="No access any classes" color="warning" icon={<CloseIcon />} />}
      {props.values.length > 0 && <Stack direction="row" gap={.5} useFlexGap justifyContent="center" flexWrap="wrap">
        {props.values.map((value) => <Chip key={value} size='small' variant='outlined' label={value} color="primary" />)}
      </Stack>}
    </Stack>
  );
}
