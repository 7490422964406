import { Checkbox, FormControlLabel, Stack } from "@mui/material";

interface CheckboxListProps {
  label: string
  allValues: string[]
  selectedValues: string[]
  onItemChange: (value: string, checked: boolean) => void
  onAllChange: (checked: boolean) => void
}

export function CheckboxList(props: CheckboxListProps) {
  const midpoint = Math.ceil(props.allValues.length / 2);

  return (
    <Stack>
      <Stack direction="row" justifyContent="flex-end" paddingRight={13}>
        <FormControlLabel
          label={props.label}
          control={
            <Checkbox
              checked={props.selectedValues.length === props.allValues.length}
              indeterminate={props.selectedValues.length > 0 && props.selectedValues.length < props.allValues.length}
              onChange={(event) => props.onAllChange(event.target.checked)}
            />
          }
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Stack ml={3} justifyContent="flex-start">
          {props.allValues.slice(0, midpoint).map((value, index) =>
            <FormControlLabel key={index} id={index.toString()} label={value} control={
              <Checkbox
                checked={props.selectedValues.includes(value)}
                onChange={(event) => props.onItemChange(value, event.target.checked)}
              />}
            />)}
        </Stack>
        <Stack ml={3} justifyContent="flex-start">
          {props.allValues.slice(midpoint).map((value, index) =>
            <FormControlLabel key={index} id={index.toString()} label={value} control={
              <Checkbox
                checked={props.selectedValues.includes(value)}
                onChange={(event) => props.onItemChange(value, event.target.checked)}
              />}
            />)}
        </Stack>
      </Stack>
    </Stack>
  )
}