import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, Stack, Typography } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { FormEvent, useState } from 'react';
import { ExamPattern, ExamPatternCategory, allLevels } from '../../components/AppConstants';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { CheckboxList } from '../../components/custom/CheckboxList';
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';
import { NumberField } from '../../components/custom/NumberField';
import { TextFieldRequired } from '../../components/custom/TextFieldRequired';
import { SPaper } from '../../components/styled/paper/SPaper';
import { SStackForm } from '../../components/styled/stack/SStackForm';
import { SStackFull } from '../../components/styled/stack/SStackFull';

interface ExamSetupEditProps {
  examPattern: ExamPattern
  onBack: () => void
  onDelete: (examPattern: ExamPattern) => void
  onSave: (examPattern: ExamPattern) => void
  onLogout: () => void
}

export function ExamSetupEdit(props: ExamSetupEditProps) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [patternName, setPatternName] = useState<string>(props.examPattern.name);
  const [attendancePresentage, setAttendancePresentage] = useState<number>(props.examPattern.attendancePercentage);
  const [homeworkPresentage, setHomeworkPresentage] = useState<number>(props.examPattern.homeworkPercentage);
  const [classPerformancePresentage, setClassPerformancePresentage] = useState<number>(props.examPattern.categories.ClassPerformance[0].categoryPercentage);
  const [classPerformanceMax, setClassPerformanceMax] = useState<number>(props.examPattern.categories.ClassPerformance[0].categoryMax);
  const [projectPresentage, setProjectPresentage] = useState<number>(props.examPattern.categories.Project[0].categoryPercentage);
  const [projectMax, setProjectMax] = useState<number>(props.examPattern.categories.Project[0].categoryMax);
  const [examCategories, setExamCategories] = useState<ExamPatternCategory[]>(props.examPattern.categories.Exam);
  const [examPercentage, setExamPercentage] = useState<number>(props.examPattern.categories.Exam.reduce((sum, category) => sum + category.categoryPercentage, 0));
  const [levels, setLevels] = useState<string[]>(props.examPattern.levels);

  const cardBorderRadius = 2;

  function onSubmitHandler(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    props.onSave({
      id: props.examPattern.id,
      name: patternName,
      levels: levels,
      attendancePercentage: attendancePresentage,
      homeworkPercentage: homeworkPresentage,
      categories: {
        ClassPerformance: [{
          id: props.examPattern.categories.ClassPerformance[0].id,
          examPatternId: props.examPattern.categories.ClassPerformance[0].examPatternId,
          categoryType: props.examPattern.categories.ClassPerformance[0].categoryType,
          categoryName: props.examPattern.categories.ClassPerformance[0].categoryName,
          categoryPercentage: classPerformancePresentage,
          categoryMax: classPerformanceMax
        }],
        Project: [{
          id: props.examPattern.categories.Project[0].id,
          examPatternId: props.examPattern.categories.Project[0].examPatternId,
          categoryType: props.examPattern.categories.Project[0].categoryType,
          categoryName: props.examPattern.categories.Project[0].categoryName,
          categoryPercentage: projectPresentage,
          categoryMax: projectMax
        }],
        Exam: examCategories.map((category) => {
          const numerator = category.categoryMax * examPercentage;
          const dinominator = examCategories.reduce((sum, category) => sum + category.categoryMax, 0);
          const categoryPercentage = dinominator > 0 ? numerator / dinominator : 0;
          return {
            id: category.id,
            examPatternId: category.examPatternId,
            categoryType: category.categoryType,
            categoryName: category.categoryName,
            categoryPercentage: categoryPercentage,
            categoryMax: category.categoryMax
          }
        })
      }
    })
  }

  return (
    <SStackForm onSubmit={onSubmitHandler}>
      <SnackbarProvider />
      <SStackFull>
        <SPaper sx={{ padding: 2, borderRadius: cardBorderRadius }}>
          <TextFieldRequired label='Name' value={patternName} baseValue={props.examPattern.name} onChange={setPatternName} />
        </SPaper>
        <SPaper sx={{ borderRadius: cardBorderRadius }}>
          <Stack direction="row" spacing={2} padding={2} alignItems="center">
            <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>Attendance</Typography>
            <Stack direction="row" gap={1}>
              <NumberField label="%" value={attendancePresentage} onChange={setAttendancePresentage} />
            </Stack>
          </Stack>
        </SPaper>
        <SPaper sx={{ borderRadius: cardBorderRadius }}>
          <Stack direction="row" spacing={2} padding={2} alignItems="center">
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Homework</Typography>
            <Stack direction="row" gap={1}>
              <NumberField label="%" value={homeworkPresentage} onChange={setHomeworkPresentage} />
            </Stack>
          </Stack>
        </SPaper>
        <SPaper sx={{ borderRadius: cardBorderRadius }}>
          <Stack direction="row" spacing={2} padding={2} alignItems="center">
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Class Performance</Typography>
            <Stack direction="row" gap={1}>
              <NumberField label="%" value={classPerformancePresentage} onChange={setClassPerformancePresentage} />
              <NumberField label="Marks" value={classPerformanceMax} onChange={setClassPerformanceMax} />
            </Stack>
          </Stack>
        </SPaper>
        <SPaper sx={{ borderRadius: cardBorderRadius }}>
          <Stack direction="row" spacing={2} padding={2} alignItems="center">
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Project</Typography>
            <Stack direction="row" gap={1}>
              <NumberField label="%" value={projectPresentage} onChange={setProjectPresentage} />
              <NumberField label="Marks" value={projectMax} onChange={setProjectMax} />
            </Stack>
          </Stack>
        </SPaper>
        <SPaper sx={{ borderRadius: cardBorderRadius }}>
          <Stack gap={2} padding={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Exam</Typography>
              <Stack direction="row" gap={1} justifyContent="right">
                <NumberField label="%" value={examPercentage} onChange={setExamPercentage} />
                <NumberField label="Marks"
                  value={examCategories.reduce((sum, category) => sum + (category.categoryMax ? category.categoryMax : 0), 0)}
                  readonly />
              </Stack>
            </Stack>
            <Divider />
            {examCategories.map((category, index) => (
              <Stack key={index} direction="row" alignItems="center">
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>{category.categoryName}</Typography>
                <Stack direction="row" gap={1}>
                  <NumberField label="Marks" value={category.categoryMax} onChange={value => {
                    const newExamCategories = examCategories.map((examCategory) => {
                      if (examCategory.categoryName === category.categoryName) {
                        return { ...examCategory, categoryMax: value }
                      } else {
                        return examCategory
                      }
                    })
                    setExamCategories(newExamCategories)
                  }} />
                </Stack>
              </Stack>
            ))
            }
          </Stack>
        </SPaper>
        <SPaper sx={{ borderRadius: cardBorderRadius }}>
          <Box border={1} borderRadius={1} borderColor='rgba(0, 0, 0, 0.23)'>
            <CheckboxList
              label='All Classes'
              allValues={allLevels}
              selectedValues={levels}
              onItemChange={(value, checked) => {
                setLevels(checked ? levels.concat(value) : levels.filter(item => item !== value))
              }}
              onAllChange={(checked) => { setLevels(checked ? allLevels : []) }} />
          </Box>
        </SPaper>
      </SStackFull>
      <BottomNavBar>
        <ConfirmationDialog
          open={showConfirmationDialog}
          message={`Are you sure you want to delete '${props.examPattern.name}'?`}
          onConfirm={() => props.onDelete(props.examPattern)}
          onCancel={() => setShowConfirmationDialog(false)} />
        <BottomNavAction label="Back" icon={<ArrowBackIosNewIcon />} onClick={props.onBack} />
        <BottomNavAction
          label="Delete"
          icon={<DeleteForeverIcon />}
          onClick={() => setShowConfirmationDialog(true)} />
        <BottomNavAction label="Save" icon={<SaveIcon />} type='submit' />
      </BottomNavBar>
    </SStackForm>
  )
}