import { Button } from "@mui/material"
import { SchoolDay } from "./Dtos";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { DateTime } from "luxon";

interface RegularDayButtonProps {
  schoolDay: SchoolDay
  isActiveDay: boolean
  onClick: () => void
}

export function RegularDayButton(props: RegularDayButtonProps) {
  DateTime.local().setLocale("en");
  const date = DateTime.fromISO(props.schoolDay.day).setZone("America/Chicago");
  const formattedDate = date.toLocaleString({
    month: "short",
    day: "2-digit",
  });

  return (
    <Button
      sx={{ mb: 1 }}
      variant={props.schoolDay.completed || props.isActiveDay ? 'contained' : 'outlined'}
      color={props.isActiveDay ? 'secondary' : 'info'}
      endIcon={props.schoolDay.completed ? <HowToRegIcon /> : undefined}
      onClick={props.onClick}>
      {formattedDate}
    </Button>
  );
}