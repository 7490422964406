import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { SPaper } from './SPaper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

interface SPaperGridProps {
  children: ReactNode
  label: string
}

export function SPaperGrid(props: SPaperGridProps) {
  return (
    <Grid container spacing={2} padding={1}>
      <Grid xs={9} padding={0} alignSelf="center">
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>{props.label}</Typography>
      </Grid>
      <Grid xs={3} padding={0} >
        {props.children}
      </Grid>
    </Grid>
  );
}