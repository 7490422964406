import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack, Typography, styled } from '@mui/material';
import { ExpandMoreProps } from '../../../pages/student/StudentCard';

interface SCardRowExpandTextProps {
  label: string
  value: string | number | undefined
  labelColor?: string
  valueColor?: string
  expanded: boolean
  handleExpandClick: () => void
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(90deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function SCardRowExpandText({
  label,
  value,
  labelColor = 'text.secondary',
  valueColor = 'text.secondary',
  expanded,
  handleExpandClick
}: SCardRowExpandTextProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="left">
        <ExpandMore size='small' expand={expanded} onClick={handleExpandClick} sx={{ padding: 0 }}        >
          <KeyboardArrowRightIcon />
        </ExpandMore>
        <Typography variant='body1' color={labelColor}>
          {label}
        </Typography>
      </Stack>
      <Typography variant='body1' color={valueColor}>
        {value}
      </Typography>
    </Stack>
  );
}