import { BottomNavigation, Paper } from '@mui/material';
import { ReactNode } from 'react';

interface BottomNavBarProps {
  value?: number
  children: ReactNode
  onChange?: (event: React.ChangeEvent<{}>, newValue: number) => void
}

export function BottomNavBar({ value = 1, children, onChange }: BottomNavBarProps) {
  return (
    <Paper elevation={24} sx={{ position: 'sticky', bottom: 0, zIndex: 1 }}>
      <BottomNavigation showLabels value={value} sx={{ position: 'sticky', bottom: 0, zIndex: 1 }} onChange={onChange}>
        {children}
      </BottomNavigation>
    </Paper>
  );
}
