import AddIcon from '@mui/icons-material/Add';
import { Backdrop, CircularProgress, Fab, Skeleton, Stack } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ExamPattern } from '../../components/AppConstants';
import { ExamSetupCard } from './ExamSetupCard';
import { ExamSetupEdit } from './ExamSetupEdit';

interface ExamSetupPageProps {
  selectedLevel: string,
  logout: () => void
}

export function ExamSetupPage(props: ExamSetupPageProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [backdrop, setBackdrop] = useState(false);
  const [examPatterns, setExamPatterns] = useState<ExamPattern[]>([])
  const [selected, setSelected] = useState<ExamPattern | undefined>(undefined)


  async function loadExamPattern() {
    try {
      console.log("Fetching exam pattern")
      setBackdrop(true)
      const response = await fetch(`api/exam/pattern`)
      if (response.ok) {
        const received: ExamPattern[] = await response.json();
        console.log("Received exam pattern: " + JSON.stringify(received))
        setExamPatterns(received)
        if (selected) {
          const existingExamPatternInDb = received.find((examPattern) => examPattern.id === selected.id);
          setSelected(existingExamPatternInDb)
        }
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No exam pattern found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    loadExamPattern();
  }, [refresh])


  async function onDelete(examPattern: ExamPattern) {
    try {
      setBackdrop(true)
      setSelected(undefined)
      const response = await fetch("api/exam/pattern/" + examPattern.id, { method: 'DELETE' })
      if (response.ok) {
        console.log("Exam pattern deleted successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('Exam pattern deleted successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Exam pattern delete failed.")
        enqueueSnackbar('Exam pattern delete failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Exam pattern delete failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  function onSave(examPattern: ExamPattern): void {
    examPattern.id === -1 ? createExamPattern(examPattern) : updateExamPattern(examPattern)
  }

  async function createExamPattern(examPattern: ExamPattern) {
    try {
      setBackdrop(true)
      setSelected(undefined)
      const response = await fetch("api/exam/pattern", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(examPattern)
      })
      if (response.ok) {
        console.log("Exam pattern created successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('Exam pattern created successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Exam pattern creation failed.")
        enqueueSnackbar('Exam pattern creation failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Exam pattern creation failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function updateExamPattern(examPattern: ExamPattern) {
    try {
      setBackdrop(true)
      setSelected(undefined)
      const response = await fetch("api/exam/pattern/" + examPattern.id, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(examPattern)
      })
      if (response.ok) {
        console.log("Exam pattern updated successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('Exam pattern updated successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Exam pattern update failed.")
        enqueueSnackbar('Exam pattern update failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Exam pattern update failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  return (
    <Stack height='100%'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarProvider />
      {loading &&
        <Stack gap={2} padding={2}>
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
        </Stack>
      }
      {!loading && !selected &&
        <Stack gap={2} padding={2}>
          {examPatterns.map((examPattern, index) => (
            <ExamSetupCard key={index} index={index + 1} examPattern={examPattern} onSelect={() => setSelected(examPattern)} />
          ))}
        </Stack>
      }
      {!loading && !selected &&
        <Fab color="secondary" sx={{ position: 'fixed', bottom: '16px', left: '50%', transform: 'translateX(-50%)' }} onClick={(_event) => {
          setSelected({
            id: -1,
            name: "",
            attendancePercentage: 0,
            homeworkPercentage: 0,
            categories: {
              ClassPerformance: [{
                id: -1,
                examPatternId: -1,
                categoryType: "ClassPerformance",
                categoryName: "Class Performance",
                categoryMax: 0,
                categoryPercentage: 0,
              }],
              Project: [{
                id: -1,
                examPatternId: -1,
                categoryType: "Project",
                categoryName: "Project",
                categoryMax: 0,
                categoryPercentage: 0,
              }],
              Exam: [{
                id: -1,
                examPatternId: -1,
                categoryType: "Exam",
                categoryName: "Typing",
                categoryMax: 0,
                categoryPercentage: 0,
              }, {
                id: -1,
                examPatternId: -1,
                categoryType: "Exam",
                categoryName: "Listening",
                categoryMax: 0,
                categoryPercentage: 0,
              }, {
                id: -1,
                examPatternId: -1,
                categoryType: "Exam",
                categoryName: "Speaking",
                categoryMax: 0,
                categoryPercentage: 0,
              }, {
                id: -1,
                examPatternId: -1,
                categoryType: "Exam",
                categoryName: "Reading",
                categoryMax: 0,
                categoryPercentage: 0,
              }, {
                id: -1,
                examPatternId: -1,
                categoryType: "Exam",
                categoryName: "Writing",
                categoryMax: 0,
                categoryPercentage: 0,
              }],
            },
            levels: [],
          })
        }}>
          <AddIcon />
        </Fab>
      }

      {!loading && selected &&
        <ExamSetupEdit
          examPattern={selected}
          onBack={() => setSelected(undefined)}
          onDelete={onDelete}
          onSave={onSave}
          onLogout={props.logout}
        />
      }
    </Stack>
  );
}