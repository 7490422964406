import { Stack, Typography } from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import { IconType } from '../../AppConstants';
import { CSSProperties } from 'react';

interface SCardRowTextProps {
  label: string;
  value: string | number | undefined;
  labelColor?: string;
  valueColor?: string;
  iconType?: IconType;
  sx?: CSSProperties;
}

export function SCardRowText({
  label,
  value,
  labelColor = 'text.secondary',
  valueColor = 'text.secondary',
  iconType = undefined,
  sx
}: SCardRowTextProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ ...(sx) }}>
      <Stack direction="row" spacing={1} alignItems="left">
        {iconType === IconType.Male && <ManIcon color='action' />}
        {iconType === IconType.Female && <WomanIcon color='action' />}
        <Typography variant='body1' color={labelColor}>
          {label}
        </Typography>
      </Stack>
      <Typography variant='body1' color={valueColor}>
        {value}
      </Typography>
    </Stack>
  );
}