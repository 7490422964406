import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

interface NaviItemProps {
  name: string;
  selected: string;
  icon: JSX.Element;
  onClick: (item: string) => void;
}

export function NaviItem(props: NaviItemProps) {
  return (<ListItem disablePadding>
    <ListItemButton selected={props.selected === props.name} onClick={() => props.onClick(props.name)}>
      <ListItemIcon>
        {props.icon}
      </ListItemIcon>
      <ListItemText>{props.name}</ListItemText>
    </ListItemButton>
  </ListItem>
  );
}