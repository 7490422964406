import { Student } from "../student/StudentPage";

export interface LibraryBook {
  id: number;
  bookName: string;
  author: string;
  copies: number;
  language: string;
  level: string;
  bigLetters: boolean;
  colorBook: boolean;
  importantLetters: boolean;
  feature: string | undefined;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export interface LibraryTransaction {
  id: number;
  bookId: number;
  userType: string;
  studentId: number | undefined;
  volunteerId: number | undefined;
  checkOutDate: string;
  dueDate: string;
  checkInDate: string | undefined;
  createdAt: string | undefined;
  createdBy: string | undefined;
  updatedAt: string | undefined;
  updatedBy: string | undefined;
}

export interface Volunteer {
  id: number;
  name: string;
  email: string;
  tamilName: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface CheckedOutBook {
  transaction: LibraryTransaction;
  book: LibraryBook;
  student: Student | undefined;
  volunteer: Volunteer | undefined;
}

export interface LibraryStats {
  libraryBooksCount: number;
  checkedOutBooksCount: number;
  transactedBooksCount: number;
}

export interface CheckOutRequest {
  bookId: number
  userType: BorrowerType
  studentId: number | null
  volunteerId: number | null
  checkOutDate: string
  dueDate: string
}

export interface UpdateCheckoutStatusRequest {
  transactionId: number
  dueDate: string
  checkInDate: string | null
}

export enum LibraryState {
  Books = "Books",
  Summary = "Summary",
  CheckOut = "CheckOut"
}

export enum BorrowerType {
  Student = "Student",
  Volunteer = "Volunteer"
}

export enum BookLanguage {
  Tamil = 'Tamil',
  English = 'English'
}

export enum BookLevel {
  Hard = 'Hard',
  Medium = 'Medium',
  Easy = 'Easy',
  NA = 'NA'
}
