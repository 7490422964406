import { CardContent } from '@mui/material';
import { ReactNode } from 'react';

interface SCardContentProps {
  children: ReactNode;
  padding?: number;
}

export function SCardContent({ children, padding = 1 }: SCardContentProps) {
  return (
    <CardContent sx={{ padding, '&:last-child': { pb: 1 } }}>
      {children}
    </CardContent>
  );
}