import { Person } from '@mui/icons-material';
import { CardHeader, Chip, Divider, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { grey } from '@mui/material/colors';
import { ExamScore } from '../../components/AppConstants';
import { SCard } from '../../components/styled/card/SCard';
import { SCardContent } from '../../components/styled/card/SCardContent';
import { SCardRowTextColumn } from '../../components/styled/card/SCardRowTextColumn';
import { SPaper } from '../../components/styled/paper/SPaper';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HowToRegIcon from '@mui/icons-material/HowToReg';

interface ExamCardProps {
  index: number
  examScore: ExamScore
  onSelect: (examScore: ExamScore) => void
}

export function ExamCard(props: ExamCardProps) {
  const attendanceMax = props.examScore.attendancePercentage
  const attendanceScore = props.examScore.attendancePercentage > 0 ? props.examScore.attendanceScore : 0

  const homeworkMax = props.examScore.homeworkPercentage
  const homeworkScore = props.examScore.homeworkPercentage > 0 ? props.examScore.homeworkScore : 0

  const classPerformance = props.examScore.categoryScores.find(categoryScore => categoryScore.categoryType === "ClassPerformance");
  const classPerformanceMax = classPerformance && classPerformance.categoryPercentage > 0 ? classPerformance.categoryMax : 0;
  const classPerformanceScore = classPerformance && classPerformance.categoryPercentage > 0 ? classPerformance.score : 0;
  const classPerformancePercentage = classPerformance ? classPerformance.categoryPercentage : 0;

  const project = props.examScore.categoryScores.find(categoryScore => categoryScore.categoryType === "Project");
  const projectMax = project && project.categoryPercentage > 0 ? project.categoryMax : 0;
  const projectScore = project && project.categoryPercentage > 0 ? project.score : 0;
  const projectPercentage = project ? project.categoryPercentage : 0;

  const group1Total = attendanceScore + homeworkScore + classPerformanceScore + projectScore;
  const group1Max = attendanceMax + homeworkMax + classPerformanceMax + projectMax;
  const group1Percentage = props.examScore.attendancePercentage + props.examScore.homeworkPercentage + classPerformancePercentage + projectPercentage;
  const group1Final = parseFloat(((group1Total / group1Max) * group1Percentage).toFixed(2))

  const examEntries = props.examScore.categoryScores.filter(categoryScore => categoryScore.categoryType === "Exam" && categoryScore.categoryPercentage > 0);
  const examCategoriesMax = Object.values(examEntries).reduce((total, { categoryMax }) => total + categoryMax, 0);
  const examCategoriesPercentage = Object.values(examEntries).reduce((total, { categoryPercentage }) => total + categoryPercentage, 0);
  const examCategoriesTotal = Object.values(examEntries).reduce((total, { score }) => total + score, 0);
  const examCategoriesFinal = parseFloat(((examCategoriesTotal / examCategoriesMax) * examCategoriesPercentage).toFixed(2))

  return (
    <SPaper >
      <SCard>
        <CardHeader
          title={props.examScore.studentName}
          titleTypographyProps={{ variant: "body1", align: "center" }}
          sx={{ padding: 1 }}
          avatar={<Chip label={props.index} />}
          action={
            <IconButton
              onClick={() => props.onSelect(props.examScore)}
              size="small"
              color="primary"
              sx={{
                margin: 1,
                bgcolor: grey[200],
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white"
                }
              }}>
              {props.examScore.emailSent ? <MarkEmailReadIcon /> : props.examScore.id === -1 ? <Person /> : <HowToRegIcon />}
            </IconButton>
          }
        />
        <Divider />
        <SCardContent>
          <Grid container spacing={2} padding={1}>
            <SCardRowTextColumn
              labelColor='text.primary'
              valueColor='text.primary'
              label="Category"
              value1="%"
              value2="Max"
              value3="Score"
              sxValue={{ textAlign: 'right' }}
            />
          </Grid>
          <Divider />
          <Grid container spacing={2} padding={1}>
            {props.examScore.attendancePercentage > 0 &&
              <SCardRowTextColumn
                label="Attendance"
                value1={props.examScore.attendancePercentage}
                value2={`[${props.examScore.attendancePresent}/${props.examScore.attendanceDays}]`}
                value3={attendanceScore}
                sxValue={{ textAlign: 'right' }}
              />
            }
            {props.examScore.homeworkPercentage > 0 &&
              <SCardRowTextColumn
                label="Homework"
                value1={props.examScore.homeworkPercentage}
                value2={`[${props.examScore.homeworkCompleted}/${props.examScore.homeworkDays}]`}
                value3={homeworkScore}
                sxValue={{ textAlign: 'right' }}
              />
            }
            {classPerformance && classPerformance.categoryPercentage > 0 &&
              <SCardRowTextColumn
                label="Class Performance"
                value1={classPerformance.categoryPercentage}
                value2={classPerformance.categoryMax}
                value3={classPerformance.score}
                sxValue={{ textAlign: 'right' }}
              />
            }
            {project && project.categoryPercentage > 0 &&
              <SCardRowTextColumn
                label="Project"
                value1={project.categoryPercentage}
                value2={project.categoryMax}
                value3={project.score}
                sxValue={{ textAlign: 'right' }}
              />
            }
          </Grid>
          <Divider />
          <Grid container spacing={2} padding={1}>
            {examEntries.map((examEntry, index) =>
              <SCardRowTextColumn
                key={index}
                label={examEntry.categoryType + ": " + examEntry.categoryName}
                value2={examEntry.categoryMax}
                value3={examEntry.score}
                sxValue={{ textAlign: 'right' }}
              />
            )}
            <SCardRowTextColumn
              label="Exam Total"
              value1={examCategoriesPercentage}
              value2={examCategoriesMax}
              value3={examCategoriesTotal}
              sxValue={{ textAlign: 'right' }}
            />
          </Grid>
        </SCardContent>
        <Divider />
        <SCardContent>
          <Grid container spacing={2} padding={1}>
            <SCardRowTextColumn
              label="Non Exam"
              value3={group1Final}
              sxValue={{ textAlign: 'right' }}
            />
            <SCardRowTextColumn
              label="Exam"
              value3={examCategoriesFinal}
              sxValue={{ textAlign: 'right' }}
            />
          </Grid>
        </SCardContent>
        <Divider />
        <SCardContent>
          <Grid container spacing={2} padding={1}>
            <SCardRowTextColumn
              label="Total"
              value3={props.examScore.total}
              sxValue={{ textAlign: 'right' }}
            />
          </Grid>
        </SCardContent>
        <Divider />
        <SCardContent>
          <Grid container spacing={2} padding={1} paddingBottom={2}>
            <textarea rows={3} cols={100} value={props.examScore.comments} disabled />
          </Grid>
        </SCardContent>
      </SCard>
    </SPaper >
  );
}
