import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Fragment } from 'react';

interface SCardRowIconProps {
  label: string;
  icon1: JSX.Element;
  icon2: JSX.Element;
  icon3: JSX.Element;
  labelColor?: string;
  valueColor?: string;
  column1Width?: number;
  column2Width?: number;
  column3Width?: number;
  column4Width?: number;
}

export function SCardRowIcon({
  label,
  icon1,
  icon2,
  icon3,
  labelColor = 'text.secondary',
  valueColor = 'text.secondary',
  column1Width = 6,
  column2Width = 2,
  column3Width = 2,
  column4Width = 2,
}: SCardRowIconProps) {
  return (
    <Fragment>
      <Grid xs={column1Width} padding={0}>
        <Typography variant="body1" color={labelColor}>
          {label}
        </Typography>
      </Grid>
      <Grid xs={column2Width} padding={0} textAlign="center">
        {icon1}
      </Grid>
      <Grid xs={column3Width} padding={0} textAlign="center">
        {icon2}
      </Grid>
      <Grid xs={column4Width} padding={0} textAlign="center">
        {icon3}
      </Grid>
    </Fragment>
  );
}