import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from "@mui/material";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { FormEvent, useState } from "react";
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { TextFieldOptional } from '../../components/custom/TextFieldOptional';
import { TextFieldRequired } from '../../components/custom/TextFieldRequired';
import { TextFieldRequiredDate } from '../../components/custom/TextFieldRequiredDate';
import { TextFieldRequiredSelect } from '../../components/custom/TextFieldRequiredSelect';
import { formatPhoneNumber } from "./StudentCard";
import { Student } from "./StudentPage";
import { AccessLevel, BooksOptions, FeeOptions, GenderOptions, PrimaryContactOptions, UserInfo } from '../../components/AppConstants';
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';

interface StudentDetailEditProps {
  userInfo: UserInfo
  allLevels: string[]
  student: Student
  onBackClick: () => void
  onDeleteClick: () => void
  onSaveClick: () => void
  logout: () => void
}

export function StudentDetailEdit(props: StudentDetailEditProps) {
  const [firstName, setFirstName] = useState<string>(props.student.firstName);
  const [lastName, setLastName] = useState<string>(props.student.lastName);
  const [level, setLevel] = useState<string>(props.student.level);
  const [fee, setFee] = useState<string>(props.student.fee);
  const [books, setBooks] = useState<string>(props.student.books);
  const [primaryContact, setPrimaryContact] = useState<string>(props.student.primaryContact);
  const [fatherName, setFatherName] = useState<string>(props.student.fatherName);
  const [motherName, setMotherName] = useState<string>(props.student.motherName);
  const [fatherEmail, setFatherEmail] = useState<string>(props.student.fatherEmail);
  const [motherEmail, setMotherEmail] = useState<string>(props.student.motherEmail);
  const [fatherPhone, setFatherPhone] = useState<string>(props.student.fatherPhone);
  const [motherPhone, setMotherPhone] = useState<string>(props.student.motherPhone);
  const [tamilName, setTamilName] = useState<string>(props.student.tamilName);
  const [dob, setDob] = useState<string>(props.student.dob);
  const [gender, setGender] = useState<string>(props.student.gender)
  const [emergencyContact, setEmergencyContact] = useState<string>(props.student.emergencyContact)
  const [foodAllergy, setFoodAllergy] = useState<string>(props.student.foodAllergy)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  async function onDeleteClick(): Promise<void> {
    try {
      const response = await fetch("api/students/" + props.student.id, { method: 'DELETE' })
      if (response.ok) {
        console.log("Student deleted successfully.")
        props.onDeleteClick()
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Student deletion failed.")
        enqueueSnackbar('Student deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Student deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
  }

  async function onSaveClick(): Promise<void> {
    try {
      const updatedStudent: Student = {
        ...props.student,
        firstName: firstName,
        lastName: lastName,
        level: level,
        fee: fee,
        books: books,
        primaryContact: primaryContact,
        fatherName: fatherName,
        motherName: motherName,
        fatherEmail: fatherEmail,
        motherEmail: motherEmail,
        fatherPhone: fatherPhone,
        motherPhone: motherPhone,
        tamilName: tamilName,
        dob: dob,
        gender: gender,
        emergencyContact: emergencyContact,
        foodAllergy: foodAllergy
      }

      const response = updatedStudent.id === -1 ? await fetch("api/students", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedStudent)
      }) : await fetch("api/students/" + props.student.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedStudent)
      })

      if (response.ok || response.status === 201) {
        console.log("Student updated successfully.")
        props.onSaveClick()
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Student save failed.")
        enqueueSnackbar('Student save failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Student save failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
  }

  function onSubmitHandler(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    onSaveClick()
  }

  return (
    <Stack height='100%' component='form' onSubmit={onSubmitHandler}>
      <Stack gap={2} padding={2} height='100%' bgcolor="white">
        <TextFieldRequired label='First name' value={firstName} baseValue={props.student.firstName} onChange={setFirstName} />
        <TextFieldRequired label='Last name' value={lastName} baseValue={props.student.lastName} onChange={setLastName} />
        <TextFieldRequiredSelect label='Level' value={level} baseValue={props.student.level} options={props.allLevels} onChange={setLevel} />
        <Stack direction="row" spacing={2} alignItems="center">
          <TextFieldRequiredSelect label='Fee' value={fee} baseValue={props.student.fee} options={Object.keys(FeeOptions)} onChange={setFee} />
          <TextFieldRequiredSelect label='Books' value={books} baseValue={props.student.books} options={Object.keys(BooksOptions)} onChange={setBooks} />
        </Stack>
        <TextFieldRequiredSelect label='Primary Contact' value={primaryContact} baseValue={props.student.primaryContact} options={Object.keys(PrimaryContactOptions)} onChange={setPrimaryContact} />
        <TextFieldRequired label='Father name' value={fatherName} baseValue={props.student.fatherName} onChange={setFatherName} />
        <TextFieldRequired label='Mother name' value={motherName} baseValue={props.student.motherName} onChange={setMotherName} />
        <Stack direction="row" spacing={2} alignItems="center">
          <TextFieldRequired label='Father phone' value={formatPhoneNumber(fatherPhone)} baseValue={formatPhoneNumber(props.student.fatherPhone)} onChange={(value) => setFatherPhone(value.replace(/\D/g, ''))} />
          <TextFieldRequired label='Mother phone' value={formatPhoneNumber(motherPhone)} baseValue={formatPhoneNumber(props.student.motherPhone)} onChange={(value) => setMotherPhone(value.replace(/\D/g, ''))} />
        </Stack>
        <TextFieldRequired label='Father email' value={fatherEmail} baseValue={props.student.fatherEmail} onChange={setFatherEmail} />
        <TextFieldRequired label='Mother email' value={motherEmail} baseValue={props.student.motherEmail} onChange={setMotherEmail} />
        <Stack direction="row" spacing={2} alignItems="center">
          <TextFieldRequiredDate label='Date Of Birth' value={dob} baseValue={props.student.dob} onChange={setDob} />
          <TextFieldRequiredSelect label='Gender' value={gender} baseValue={props.student.gender} options={Object.keys(GenderOptions)} onChange={setGender} />
        </Stack>
        <TextFieldOptional label='Tamil name' value={tamilName} baseValue={props.student.tamilName} onChange={setTamilName} />
        <TextFieldOptional label='Emergency contact' value={emergencyContact} baseValue={props.student.emergencyContact} onChange={setEmergencyContact} />
        <TextFieldOptional label='Food allergy' value={foodAllergy} baseValue={props.student.foodAllergy} onChange={setFoodAllergy} />
      </Stack >
      <BottomNavBar>
        <ConfirmationDialog
          open={showConfirmationDialog}
          message={`Are you sure you want to delete '${props.student.firstName} ${props.student.lastName}'?`}
          onConfirm={onDeleteClick}
          onCancel={() => setShowConfirmationDialog(false)} />
        <BottomNavAction label="Back" icon={<ArrowBackIosNewIcon />} onClick={props.onBackClick} />
        {props.userInfo.studentInfoAccess === AccessLevel.ReadWrite &&

          <BottomNavAction label="Delete" icon={<DeleteForeverIcon />} onClick={() => setShowConfirmationDialog(true)} />
        }
        <BottomNavAction label="Save" icon={<SaveIcon />} type='submit' />
      </BottomNavBar>
      <SnackbarProvider />
    </Stack >
  )
}