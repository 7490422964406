import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SearchIcon from '@mui/icons-material/Search';
import { Chip } from '@mui/material';
import { AccessLevel } from '../AppConstants';

interface ChipAccessProps {
  access: AccessLevel
}

export function ChipAccess(props: ChipAccessProps) {
  const color = props.access === AccessLevel.NoAccess ? "warning" : props.access === AccessLevel.ReadOnly ? "primary" : "success";
  const icon = props.access === AccessLevel.NoAccess ? <CloseIcon /> : props.access === AccessLevel.ReadOnly ? <SearchIcon /> : <ModeEditIcon />;
  return (
    <Chip size='small' variant='outlined' label={props.access} color={color} icon={icon} />
  );
}
