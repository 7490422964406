import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface DropDownProps {
  label: string
  selectedValue: string | undefined
  allValues: string[]
  onChange: (value: string) => void
}

export function DropDown(props: DropDownProps) {
  const devId = '10'
  return (
    <FormControl fullWidth >
      <InputLabel id={props.label + "dropDownLabel" + devId}>Level</InputLabel>
      <Select
        labelId={props.label + "dropDownLabel" + devId}
        id={props.label + "dropDown" + devId}
        value={props.selectedValue}
        label={props.label}
        onChange={(event) => props.onChange(event.target.value)}
      >
        {props.allValues.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
      </Select>
    </FormControl>
  );
}