import { Stack } from "@mui/material";
import { SchoolDay, TeacherDayStatus } from "./Dtos";
import { TeacherSwitch } from "./TeacherSwitch";

interface TeacherSwitchListProps {
  selectedDay: SchoolDay
  selectedStatus: string
  teacherDayStatuses: TeacherDayStatus[]
  onTeacherSwitchChange: (teacherId: Number, isSelected: boolean) => void
}

export function TeacherSwitchList(props: TeacherSwitchListProps) {
  return (
    <Stack gap={2} >
      {props.teacherDayStatuses.length > 0 &&
        props.teacherDayStatuses.map((teacherDayStatus, index) =>
          <TeacherSwitch
            key={index}
            index={index + 1}
            selectedDay={props.selectedDay}
            selectedStatus={props.selectedStatus}
            teacherDayStatus={teacherDayStatus}
            onTeacherSwitchChange={props.onTeacherSwitchChange} />
        )
      }
    </Stack>
  );
}