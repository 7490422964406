import { Person } from '@mui/icons-material';
import { CardHeader, Chip, Divider, IconButton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AccessLevel, UserInfo, getAccessLevelFromString } from '../../components/AppConstants';
import { ChipAccess } from '../../components/custom/ChipAccess';
import { GridEntryMultipleValues } from '../../components/custom/GridEntryMultipleValues';
import { SCard } from '../../components/styled/card/SCard';
import { SCardContent } from '../../components/styled/card/SCardContent';
import { SPaper } from '../../components/styled/paper/SPaper';
import { formatPhoneNumber } from '../student/StudentCard';

interface UserCardProps {
  loggedInUser: UserInfo
  index: number
  userInfo: UserInfo
  onPersonClick: (userInfo: UserInfo) => void
}

export function UserCard(props: UserCardProps) {
  return (
    <SPaper >
      <SCard>
        <CardHeader
          title={props.userInfo.name}
          titleTypographyProps={{ variant: "body1", align: "center" }}
          subheader={<Chip size='small' label={props.userInfo.type} />}
          subheaderTypographyProps={{ variant: "subtitle1", align: "center", }}
          sx={{ padding: 1 }}
          avatar={<Chip label={props.index} />}
          action={props.loggedInUser.userAccess === AccessLevel.ReadWrite &&
            <IconButton
              onClick={() => props.onPersonClick(props.userInfo)}
              size="small"
              color="primary"
              sx={{
                margin: 1,
                bgcolor: grey[200],
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white"
                }
              }}>
              <Person />
            </IconButton>
          }
        />
        <Divider />
        <Typography noWrap variant="body1" color="text.secondary" padding={1} textAlign="center">
          {props.userInfo.email}
        </Typography>
        <Divider />
        <SCardContent>
          <Typography noWrap variant="body1" color="text.secondary" textAlign="center">
            {props.userInfo.tamilName}
          </Typography>
          <Typography noWrap variant="body1" color="text.secondary" textAlign="center">
            {formatPhoneNumber(props.userInfo.phone)}
          </Typography>
          <GridEntryMultipleValues label="Classes" values={props.userInfo.allLevels ? ["Access to all levels"] : props.userInfo.level} />
          <Divider sx={{ margin: 1 }} />
          <Stack direction="row" justifyContent="space-between" margin={1}>
            <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" gap={1} width="100%">
              <Typography variant="body2" color="text.secondary">
                Library
              </Typography>
              <ChipAccess access={getAccessLevelFromString(props.userInfo.libraryAccess)} />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" gap={1} width="100%">
              <Typography variant="body2" color="text.secondary">
                StudentInfo
              </Typography>
              <ChipAccess access={getAccessLevelFromString(props.userInfo.studentInfoAccess)} />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" margin={1}>
            <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" gap={1} width="100%">
              <Typography variant="body2" color="text.secondary">
                User Management
              </Typography>
              <ChipAccess access={getAccessLevelFromString(props.userInfo.userAccess)} />
            </Stack>
          </Stack>
        </SCardContent>
      </SCard>
    </SPaper >
  );
}
