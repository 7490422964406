import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Backdrop, Button, ButtonGroup, Chip, CircularProgress, Pagination, PaginationItem, Skeleton, Stack } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { SchoolDay, StudentDayStatus } from './Dtos';
import { RegularDayButton } from './RegularDayButton';
import { StudentSwitchList } from "./StudentSwitchList";
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';

interface StudentDayProps {
  urlPath: string,
  selectedYear: string
  selectedLevel: string,
  selectedStatus: string,
  deselectedStatus: string,
  logout: () => void
}

export function StudentDayComponent(props: StudentDayProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [activeTerm, setActiveTerm] = useState<string>("Term1")
  const [termDays, setTermDays] = useState<Map<string, SchoolDay[]>>(new Map<string, SchoolDay[]>())
  const [activeDay, setActiveDay] = useState<SchoolDay | undefined>(undefined)
  const [studentDayStatuses, setStudentDayStatuses] = useState<StudentDayStatus[]>([])
  const [studentDayStatusesBackup, setStudentDayStatusesBackup] = useState<StudentDayStatus[]>([])
  const [noOfStudents, setNoOfStudents] = useState(8)
  const [backdrop, setBackdrop] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  async function loadSchoolDays() {
    try {
      console.log("Fetching schoolDays for level: " + props.urlPath + " day: " + activeDay?.day)
      const dayFilter = activeDay?.day ? `&day=${activeDay.id}` : ""
      setBackdrop(true)
      const response = await fetch(`api/${props.urlPath}/days?year=${props.selectedYear}&level=${props.selectedLevel}${dayFilter}`)
      if (response.ok) {
        const allSchoolDays: SchoolDay[] = await response.json();
        const workdingTermDays = new Map<string, SchoolDay[]>()
        workdingTermDays.set("Term1", allSchoolDays.filter((schoolDay) => schoolDay.term === "Term1"))
        workdingTermDays.set("Term2", allSchoolDays.filter((schoolDay) => schoolDay.term === "Term2"))
        workdingTermDays.set("Term3", allSchoolDays.filter((schoolDay) => schoolDay.term === "Term3"))
        setTermDays(workdingTermDays)
        const activeDayReceived = allSchoolDays.find((schoolDay) => schoolDay.active);
        setActiveDay(activeDayReceived)
        activeDayReceived && setActiveTerm(activeDayReceived.term)
        console.log("ActiveDay: " + allSchoolDays.find((schoolDay) => schoolDay.active)?.day)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No schoolDays found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    loadSchoolDays();
  }, [props.urlPath, props.selectedYear, props.selectedLevel, refresh])

  async function loadStudentDayStatus(day: number) {
    try {
      setStudentDayStatuses([]) // Clear studentDayStatuses while loading
      console.log(`Fetching ${props.urlPath} for level: ${props.selectedLevel} day: ${day}`)
      setBackdrop(true)
      const response = await fetch(`api/${props.urlPath}/students?level=${props.selectedLevel}&day=${day}`)
      if (response.ok) {
        const result: StudentDayStatus[] = await response.json();
        setStudentDayStatuses(result)
        setStudentDayStatusesBackup(result)
        setNoOfStudents(result.length)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No studentDayStatus found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    if (activeDay) {
      loadStudentDayStatus(activeDay?.id);
    }
  }, [props.urlPath, props.selectedYear, props.selectedLevel, activeDay?.id, refresh])

  function handleOnStudentDayStatusChange(studentId: Number, isSelected: boolean): void {
    console.log("StudentId: " + studentId + " isSelected: " + isSelected)
    const updatedStudentDayStatuses = studentDayStatuses.map((studentDayStatus) => {
      if (studentDayStatus.studentId === studentId) {
        return { ...studentDayStatus, status: isSelected ? props.selectedStatus : props.deselectedStatus }
      }
      return studentDayStatus
    })
    setStudentDayStatuses(updatedStudentDayStatuses)
  }

  function cancelHandler(): void {
    setStudentDayStatuses(studentDayStatusesBackup)
  }

  async function saveHandler(): Promise<void> {
    try {
      console.log(`Saving ${props.urlPath} for day: ${activeDay?.day}`)
      setBackdrop(true)
      const response = await fetch(`api/${props.urlPath}/students`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(studentDayStatuses)
      })
      if (response.ok) {
        console.log(`${props.urlPath} saved successfully.`)
        setRefresh(!refresh)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log(`${props.urlPath} save failed.`)
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  async function deleteHandler(): Promise<void> {
    setShowConfirmationDialog(false)
    if (activeDay) {
      try {
        console.log(`Deleting ${props.urlPath} for day: ${activeDay.day} level: ${props.selectedLevel}`)
        setBackdrop(true)
        const response = await fetch(`api/${props.urlPath}/students?level=${props.selectedLevel}&day=${activeDay.id}`, {
          method: 'DELETE',
        })
        if (response.ok) {
          console.log(`${props.urlPath} deleted successfully.`)
          setRefresh(!refresh)
        } else if (response.status === 401) {
          props.logout()
        } else {
          console.log(`${props.urlPath} delete failed.`)
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  }

  const studentsSkeleton = [...Array(noOfStudents)].map((_, index) => <Skeleton variant="rectangular" height={54} key={index} />)

  function onTermChange(term: string): void {
    setActiveTerm(term)
    const activeDayInTerm = termDays.get(term)?.find((schoolDay) => schoolDay.active) ?? termDays.get(term)?.[0]
    setActiveDay(activeDayInTerm)
  }

  return (
    <Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack gap={2} padding={2} height='100%'>
        <Stack direction="row" justifyContent="center">
          <ButtonGroup variant='outlined'>
            <Button color='info' variant={activeTerm === 'Term1' ? 'contained' : 'outlined'} onClick={() => onTermChange('Term1')}>Term 1</Button>
            <Button color='info' variant={activeTerm === 'Term2' ? 'contained' : 'outlined'} onClick={() => onTermChange('Term2')}>Term 2</Button>
            <Button color='info' variant={activeTerm === 'Term3' ? 'contained' : 'outlined'} onClick={() => onTermChange('Term3')}>Term 3</Button>
          </ButtonGroup>
        </Stack>
        <Stack direction="row" justifyContent="center" >
          {/* Special style to allow wrap and complete the button border */}
          <ButtonGroup
            variant='outlined'
            size='small'
            sx={{
              flexWrap: "wrap",
              justifyContent: "center",
              ".MuiButtonGroup-grouped:not(:last-of-type)": {
                borderRightColor: "info.main"
              }
            }}>
            {termDays.get(activeTerm)?.map(schoolDay => <RegularDayButton schoolDay={schoolDay} isActiveDay={activeDay?.id === schoolDay.id} key={schoolDay.id} onClick={() => setActiveDay(schoolDay)} />)}
          </ButtonGroup>
        </Stack>
        {studentDayStatuses.length === 0 &&
          <Stack gap={2} height="100%">
            {studentsSkeleton}
          </Stack>
        }
        {activeDay && studentDayStatuses.length > 0 &&
          <Stack height="100%">
            <StudentSwitchList
              selectedLevel={props.selectedLevel}
              selectedDay={activeDay}
              selectedStatus={props.selectedStatus}
              studentDayStatuses={studentDayStatuses}
              onStudentSwitchChange={handleOnStudentDayStatusChange} />
          </Stack>
        }
      </Stack>
      {activeDay &&
        <BottomNavBar>
          <ConfirmationDialog
            open={showConfirmationDialog}
            message={`Are you sure you want to delete '${props.urlPath}' for '${activeDay.day}'?`}
            onConfirm={deleteHandler}
            onCancel={() => setShowConfirmationDialog(false)} />
          <BottomNavAction label="Reload" icon={<RestartAltIcon />} onClick={cancelHandler} />
          <BottomNavAction label="Delete" icon={<DeleteForeverIcon />} onClick={() => setShowConfirmationDialog(true)} loading={!(activeDay && activeDay.completed)} />
          <BottomNavAction label="Save" icon={<SaveIcon />} onClick={saveHandler} />
        </BottomNavBar>
      }
    </Fragment>
  );
}