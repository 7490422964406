import { LockOutlined } from '@mui/icons-material';
import { Avatar, Link, Paper, Stack, Typography } from "@mui/material";
import { MicrosoftLoginButton } from 'react-social-login-buttons';

export function LoginPage() {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{
      backgroundImage: "url('images/thiruvalluvar.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      minHeight: '100vh',
      width: '100%',
    }}>
      <Paper elevation={24} sx={{ margin: 5, padding: 2 }}>
        <Stack spacing={2} alignItems={'center'}>
          <Avatar sx={{ bgcolor: 'secondary.main' }}>
            <LockOutlined />
          </Avatar>
          <Typography variant={"body1"} color="secondary.dark" paddingBottom={1} fontWeight="bold" >
            மேற்கு கேட்டி தமிழ்ப்பள்ளி
          </Typography>
          {/* <Link href='/auth/google/login' underline='none' color="inherit">
                <GoogleLoginButton />
              </Link> */}
          <Link href='/auth/ms/login' underline='none' color="inherit">
            <MicrosoftLoginButton />
          </Link>
        </Stack>
      </Paper>
    </Stack >
  );
}
