import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { CardHeader, Divider, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { FormEvent, useEffect, useState } from 'react';
import { ExamCategoryScore, ExamScore } from '../../components/AppConstants';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';
import { NumberField } from '../../components/custom/NumberField';
import { TextFieldRequired } from '../../components/custom/TextFieldRequired';
import { SPaper } from '../../components/styled/paper/SPaper';
import { SPaperGrid } from '../../components/styled/paper/SPaperGrid';
import { SStackForm } from '../../components/styled/stack/SStackForm';
import { SStackFull } from '../../components/styled/stack/SStackFull';
import { SCard } from '../../components/styled/card/SCard';
import { SBackdrop } from '../../components/styled/backdrop/SBackdrop';

interface ExamEditProps {
  term?: string
  examScore: ExamScore
  onBack: (refresh: boolean, message: string) => void
  logout: () => void
}

export function ExamEdit(props: ExamEditProps) {
  const [backdrop, setBackdrop] = useState<boolean>(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const attendanceScore = props.examScore.attendancePercentage > 0 ? props.examScore.attendanceScore : 0;
  const homeworkScore = props.examScore.homeworkPercentage > 0 ? props.examScore.homeworkScore : 0;

  const classPerformance = props.examScore.categoryScores.find(categoryScore => categoryScore.categoryType === "ClassPerformance");
  const [classPerformanceScore, setClassPerformanceScore] = useState<number>(classPerformance ? classPerformance.score : 0);
  const classPerformanceMax = classPerformance && classPerformance.categoryPercentage > 0 ? classPerformance.categoryMax : 0;
  const classPerformancePercentage = classPerformance ? classPerformance.categoryPercentage : 0;

  const project = props.examScore.categoryScores.find(categoryScore => categoryScore.categoryType === "Project");
  const [projectScore, setProjectScore] = useState<number>(project ? project.score : 0);
  const projectMax = project && project.categoryPercentage > 0 ? project.categoryMax : 0;
  const projectPercentage = project ? project.categoryPercentage : 0;

  const group1Max = props.examScore.attendancePercentage + props.examScore.homeworkPercentage + classPerformanceMax + projectMax;

  const [examCategoryScoreMap, setExamCategoryScoreMap] = useState<Record<string, ExamCategoryScore>>(Object.fromEntries(
    props.examScore.categoryScores
      .filter(category => category.categoryType === "Exam" && category.categoryPercentage > 0 )
      .map((score) => [score.categoryName, score])
  ))
  
  const examCategoriesPercentage = Object.values(examCategoryScoreMap).reduce((total, { categoryPercentage }) => total + categoryPercentage, 0);
  const examCategoriesMax = Object.values(examCategoryScoreMap).reduce((total, { categoryMax }) => total + categoryMax, 0);

  const [examCategoriesFinal, setExamCategoriesFinal] = useState<number>(0);
  const [comments, setComments] = useState<string>(props.examScore.comments);
  const [total, setTotal] = useState<number>(props.examScore.total);
  const [group1Final, setGroup1Final] = useState<number>(0);

  useEffect(() => {
    // const classPerformanceFinal = (classPerformanceScore / classPerformanceMax) * classPerformance.categoryPercentage
    const classPerformanceFinal = isNaN(classPerformanceMax) || classPerformanceMax === 0 ? 0 : (classPerformanceScore / classPerformanceMax) * classPerformancePercentage;
    const projectFinal = isNaN(projectMax) || projectMax === 0 ? 0 : (projectScore / projectMax) * projectPercentage;
    const group1Total = attendanceScore + homeworkScore + classPerformanceFinal + projectFinal;
    setGroup1Final(group1Total)

    const examTotal = Object.values(examCategoryScoreMap).reduce((total, { score }) => total + score, 0)
    const examTotalFinal = (examTotal / examCategoriesMax) * examCategoriesPercentage
    setExamCategoriesFinal(examTotalFinal)

    setTotal(group1Total + examTotalFinal)
  }, [classPerformanceScore, projectScore, examCategoryScoreMap])

  const handleScoreUpdate = (categoryName: string, newScore: number) => {
    setExamCategoryScoreMap((prevCategoryScoreMap) => {
      // Create a copy of the previous state
      const updatedCategoryScoreMap = { ...prevCategoryScoreMap };

      // Update the score for the specified category
      if (updatedCategoryScoreMap.hasOwnProperty(categoryName)) {
        updatedCategoryScoreMap[categoryName] = {
          ...updatedCategoryScoreMap[categoryName],
          score: newScore,
        };
      }

      return updatedCategoryScoreMap;
    });
  };

  function onSubmitHandler(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    const categoryScores = [...Object.values(examCategoryScoreMap)];
    if (classPerformance) {
      classPerformance.score = classPerformanceScore
      categoryScores.push(classPerformance);
    }
    if (project) {
      project.score = projectScore
      categoryScores.push(project);
    }

    onSave({
      id: props.examScore.id,
      examPatternId: props.examScore.examPatternId,
      schoolYear: props.examScore.schoolYear,
      dayId: props.examScore.dayId,
      day: props.examScore.day,
      studentId: props.examScore.studentId,
      studentName: props.examScore.studentName,
      level: props.examScore.level,
      fatherEmail: props.examScore.fatherEmail,
      motherEmail: props.examScore.motherEmail,
      attendancePercentage: props.examScore.attendancePercentage,
      attendanceDays: props.examScore.attendanceDays,
      attendancePresent: props.examScore.attendancePresent,
      attendanceScore: props.examScore.attendanceScore,
      homeworkPercentage: props.examScore.homeworkPercentage,
      homeworkDays: props.examScore.homeworkDays,
      homeworkCompleted: props.examScore.homeworkCompleted,
      homeworkScore: props.examScore.homeworkScore,
      categoryScores: categoryScores.filter((score): score is ExamCategoryScore => score !== undefined),
      total: total,
      comments: comments,
      emailSent: props.examScore.emailSent,
    })
  }

  async function onSave(examScore: ExamScore) {
    try {
      setBackdrop(true)
      const isNewScore = examScore.id === -1
      const scoreId = isNewScore ? "" : "/" + examScore.id
      const response = await fetch(`api/exam/students${scoreId}`, {
        method: isNewScore ? 'POST' : 'PUT',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(examScore)
      })
      if (response.ok) {
        console.log("Score saved successfully.")
        props.onBack(true, "Score saved successfully.")
        enqueueSnackbar('Score saved successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Score saving failed. " + response.status + " " + response.statusText)
        enqueueSnackbar('Score saving failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Score saving failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function onDelete() {
    try {
      setBackdrop(true)
      const response = await fetch("api/exam/students/" + props.examScore.id, { method: 'DELETE' })
      if (response.ok) {
        console.log("Score deleted successfully.")
        props.onBack(true, "Score deleted successfully.")
        enqueueSnackbar('Score deleted successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Score deletion failed.")
        enqueueSnackbar('Score deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Score deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  return (
    <SStackForm onSubmit={onSubmitHandler}>
      <SnackbarProvider />
      <SBackdrop open={backdrop} />
      <SStackFull>
        <SPaper sx={{ borderRadius: 2 }}>
          <SCard sx={{ borderRadius: 2, padding: 1 }}>
            <CardHeader
              title={props.examScore.studentName}
              titleTypographyProps={{ variant: "h6", align: "center" }}
              subheaderTypographyProps={{ variant: "subtitle1", align: "center" }}
              subheader={props.term}
              sx={{ padding: 1 }}
            />
            <Divider />
            <Stack gap={2} paddingTop={1}>
              {props.examScore.attendancePercentage > 0 &&
                <SPaperGrid label={"Attendance"}>
                  <NumberField label={`max ${props.examScore.attendancePercentage}`} value={props.examScore.attendanceScore} readonly />
                </SPaperGrid>
              }
              {props.examScore.homeworkPercentage > 0 &&
                <SPaperGrid label={"Homework"}>
                  <NumberField
                    label={`max ${props.examScore.homeworkPercentage}`}
                    value={props.examScore.homeworkScore}
                    readonly />
                </SPaperGrid>
              }
              {classPerformance && classPerformance.categoryPercentage > 0 &&
                <SPaperGrid label={"Class Performance"}>
                  <NumberField
                    label={`max ${classPerformance.categoryMax}`}
                    min={0}
                    max={classPerformance.categoryMax}
                    value={classPerformanceScore}
                    onChange={setClassPerformanceScore} />
                </SPaperGrid>
              }
              {project && project.categoryPercentage > 0 &&
                <SPaperGrid label={"Project"}>
                  <NumberField
                    min={0}
                    max={project.categoryMax}
                    label={`max ${project.categoryMax}`}
                    value={projectScore}
                    onChange={setProjectScore} />
                </SPaperGrid>
              }
              <Divider />
              {Object.entries(examCategoryScoreMap).map(([categoryName, categoryScore]) =>
                categoryScore.categoryPercentage > 0 &&
                <SPaperGrid label={categoryScore.categoryType + ": " + categoryScore.categoryName} key={categoryName}>
                  <NumberField
                    key={categoryName}
                    min={0}
                    max={categoryScore.categoryMax}
                    label={`max ${categoryScore.categoryMax}`}
                    value={categoryScore.score}
                    onChange={value => handleScoreUpdate(categoryName, value)} />
                </SPaperGrid>
              )}
              <Divider />
              <Grid container spacing={2} padding={1}>
                <Grid xs={3} padding={0} alignSelf="center">
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Total</Typography>
                </Grid>
                <Grid xs={3} padding={0} paddingRight={1} >
                  <Stack direction="row" gap={1}>
                    <NumberField label={`${group1Max}`} value={group1Final} readonly />
                    <Typography variant="subtitle1" alignSelf="center"> + </Typography>
                  </Stack>
                </Grid>
                <Grid xs={3} padding={0} paddingRight={1} >
                  <Stack direction="row" gap={1}>
                    <NumberField label={`${examCategoriesPercentage}`} value={examCategoriesFinal} readonly />
                    <Typography variant="subtitle1" alignSelf="center"> = </Typography>
                  </Stack>
                </Grid>
                <Grid xs={3} padding={0} >
                  <NumberField label='100' value={total} readonly />
                </Grid>
              </Grid>

              <TextFieldRequired
                label='Comments'
                rows={3}
                value={comments}
                baseValue={props.examScore.comments}
                onChange={setComments} />
            </Stack>
          </SCard>
        </SPaper>
      </SStackFull >
      <BottomNavBar>
        <ConfirmationDialog
          open={showConfirmationDialog}
          message={`Are you sure you want to delete '${props.examScore.studentName} exam score'?`}
          onConfirm={() => onDelete()}
          onCancel={() => setShowConfirmationDialog(false)} />
        <BottomNavAction label="Back" icon={<ArrowBackIosNewIcon />} onClick={() => props.onBack(false, "")} />
        <BottomNavAction label="Delete" icon={<DeleteForeverIcon />} onClick={() => setShowConfirmationDialog(true)} loading={props.examScore.id === -1} />
        <BottomNavAction label="Save" icon={<SaveIcon />} type='submit' />
      </BottomNavBar>
    </SStackForm>
  )
}