import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemIcon, Menu, MenuItem, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { PageName, UserInfo } from './AppConstants';

interface HeaderProps {
  userInfo: UserInfo
  selectedPage: string
  selectedYear: string
  selectedLevel: string
  isTest: boolean
  onDrawerToggle: () => void;
}

export function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function formatName(name: string): string {
    name = name.trim();

    if (name === '') return '?';

    const words = name.split(' ');
    return words.map(word => word.charAt(0)).join('').slice(0, 2);
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: "secondary.main",
      },
      children: formatName(name)
    };
  }

  return (
    <React.Fragment>
      <AppBar color={props.isTest ? "warning" : "primary"} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { xs: 'block' } }} >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs >
              <Stack gap={1} margin={1}>
                <Typography
                  variant="body1"
                  align='center'
                  fontSize={10}
                  style={{ color: props.selectedYear !== props.userInfo.year[0] ? 'yellow' : 'inherit' }}>
                  {props.isTest ? `[TEST ENV] ` : ''}மேற்கு கேட்டி தமிழ்ப்பள்ளி{props.selectedYear !== props.userInfo.year[0] ? ` [${props.selectedYear}]` : ''}
                </Typography>
                {(props.selectedPage === PageName.Attendance
                  || props.selectedPage === PageName.Exam
                  || props.selectedPage === PageName.Homework
                  || props.selectedPage === PageName.Students) &&
                  <Typography variant="h6" align='center' >
                    {props.selectedPage} - {props.selectedLevel}
                  </Typography>}
                {(props.selectedPage === PageName.Library
                  || props.selectedPage === PageName.Users
                  || props.selectedPage === PageName.AllStudents
                  || props.selectedPage === PageName.ExamSummary
                  || props.selectedPage === PageName.Log
                  || props.selectedPage === PageName.TeacherAttendance
                  || props.selectedPage === PageName.ExamSetup) &&
                  <Typography variant="h6" align='center' >
                    {props.selectedPage}
                  </Typography>}
              </Stack>
            </Grid>
            <Grid >
              <IconButton onClick={handleClick} color="inherit" sx={{ p: 0.5 }}>
                <Avatar {...stringAvatar(props.userInfo.name)} alt={props.userInfo.name} />
              </IconButton>
            </Grid>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar /> My profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link href='/auth/logout?scope=app' underline='none' color="inherit" display="flex" alignItems="center">
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout App
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link href='/auth/logout?scope=sso' underline='none' color="inherit" display="flex" alignItems="center">
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                Logout SSO
              </Link>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </React.Fragment >
  );
}