import { Stack } from '@mui/material';
import { ReactNode } from 'react';

interface SStackWhiteProps {
  children: ReactNode;
  space?: number;
}

export function SStackWhite({ children, space = 2 }: SStackWhiteProps) {
  return (
    <Stack gap={space} padding={space} height="100%" bgcolor="white">
      {children}
    </Stack>
  );
}