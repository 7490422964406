import { Person } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardActions, CardHeader, Chip, Collapse, Divider, IconButton, IconButtonProps, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import { useState } from "react";
import { BooksOptions, FeeOptions, PrimaryContactOptions, defaultDateFormat } from "../../components/AppConstants";
import { SCard } from "../../components/styled/card/SCard";
import { SCardContent } from "../../components/styled/card/SCardContent";
import { SCardRowChip } from "../../components/styled/card/SCardRowChip";
import { SCardRowText } from "../../components/styled/card/SCardRowText";
import { SPaper } from "../../components/styled/paper/SPaper";
import { Student } from "./StudentPage";

interface StudentCardProps {
  index: number,
  student: Student
  allLevels: boolean
  onPersonClick: (student: Student) => void
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  // Remove any non-digit characters from the phone number
  const cleaned = phoneNumber.replace(/\D/g, '').slice(0, 10);

  // Apply the full format
  const fullMatch = cleaned.match(/^(\d{3})(\d{3})(\d+)$/);
  if (fullMatch) {
    return `(${fullMatch[1]}) ${fullMatch[2]}-${fullMatch[3]}`;
  }

  // Apply the partial format
  const parrtialMatch1 = cleaned.match(/^(\d{3})(\d+)$/);
  if (parrtialMatch1) {
    return `(${parrtialMatch1[1]}) ${parrtialMatch1[2]}`;
  }

  // Return the original phone number if it couldn't be formatted
  return phoneNumber;
};

export interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function StudentCard(props: StudentCardProps) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const feeColor = props.student.fee === FeeOptions.Paid ? 'success' : 'warning';
  const booksColor = props.student.books === BooksOptions.Collected ? 'success' : 'warning';

  return (
    <SPaper>
      <SCard>
        <CardHeader
          title={props.student.firstName + " " + props.student.lastName}
          titleTypographyProps={{ variant: "h6", align: "center", }}
          subheader={props.allLevels && <Chip size='small' label={props.student.level} />}
          subheaderTypographyProps={{ variant: "subtitle1", align: "center", }}
          sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: props.allLevels ? 1 : 0, paddingBottom: props.allLevels ? 1 : 0 }}
          avatar={<Chip label={props.index} />}
          action={
            <IconButton
              onClick={() => props.onPersonClick(props.student)}
              size="small"
              color="primary"
              sx={{
                margin: 1,
                bgcolor: grey[200],
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white"
                }
              }}>
              <Person />
            </IconButton>
          }
        />
        <Divider />
        <SCardContent>
          <Stack gap={1}>
            <SCardRowText
              label={props.student.fatherName}
              value={formatPhoneNumber(props.student.fatherPhone)}
              labelColor={props.student.primaryContact === PrimaryContactOptions.Father ? "primary.main" : "text.secondary"}
              valueColor={props.student.primaryContact === PrimaryContactOptions.Father ? "primary.main" : "text.secondary"}
            />
            <SCardRowText
              label={props.student.motherName}
              value={formatPhoneNumber(props.student.motherPhone)}
              labelColor={props.student.primaryContact === PrimaryContactOptions.Mother ? "primary.main" : "text.secondary"}
              valueColor={props.student.primaryContact === PrimaryContactOptions.Mother ? "primary.main" : "text.secondary"}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <SCardContent padding={0}>
                <Stack gap={1}>
                  <SCardRowChip label="Fee" value={props.student.fee} color={feeColor} />
                  <SCardRowChip label="Books" value={props.student.books} color={booksColor} />
                  <SCardRowText label="Father" value={props.student.fatherEmail} />
                  <SCardRowText label="Mother" value={props.student.motherEmail} />
                  <SCardRowText label="Emergency Contact" value={props.student.emergencyContact} />
                  <SCardRowText label="Tamil Name" value={props.student.tamilName} />
                  <SCardRowText label="Date Of Birth" value={DateTime.fromFormat(props.student.dob, defaultDateFormat).toLocaleString(DateTime.DATE_MED)} />
                  <SCardRowText label="Gender" value={props.student.gender} />
                  <SCardRowText label="Food Allergy" value={props.student.foodAllergy} />
                </Stack>
              </SCardContent>
            </Collapse>
          </Stack>
        </SCardContent>
        <CardActions sx={{ justifyContent: "center", padding: 0 }}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </SCard>
    </SPaper>
  );
}