import { Stack } from '@mui/material';
import { ReactNode } from 'react';

interface SStackFullProps {
  children: ReactNode;
  space?: number;
}

export function SStackFull({ children, space = 2 }: SStackFullProps) {
  return (
    <Stack gap={space} padding={space} height="100%">
      {children}
    </Stack>
  );
}