import { TextField } from "@mui/material";
import { getColor } from "./TextFieldOptional";

interface TextFieldRequiredProps {
  label: string
  value: string
  baseValue: string
  rows?: number
  onChange: (newValue: string) => void
}

export function getColorRequired(currentValue: string, originalValue: string) {
  if (currentValue === '') return "error"
  return getColor(currentValue, originalValue)
}

export function TextFieldRequired({
  label,
  value,
  baseValue,
  rows = 1,
  onChange
}: TextFieldRequiredProps) {
  return (
    <TextField
      required
      fullWidth
      multiline
      rows={rows}
      size="small"
      label={label}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  )
}