import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SaveIcon from '@mui/icons-material/Save';
import { CardHeader, Divider, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { SnackbarKey, SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ExamSummaryReport } from '../../components/AppConstants';
import { SBackdrop } from '../../components/styled/backdrop/SBackdrop';
import { SCard } from '../../components/styled/card/SCard';
import { SCardContent } from '../../components/styled/card/SCardContent';
import { SCardRowIcon } from '../../components/styled/card/SCardRowIcon';
import { SCardRowTextColumn } from '../../components/styled/card/SCardRowTextColumn';
import { SPaper } from '../../components/styled/paper/SPaper';
interface ExamSummaryPageProps {
  selectedYear: string
  logout: () => void
}

export function ExamSummaryPage(props: ExamSummaryPageProps) {
  const [loading, setLoading] = useState<boolean>(true)
  const [backdrop, setBackdrop] = useState(false);
  const [examSummaryReports, setExamSummaryReports] = useState<ExamSummaryReport[]>([])

  async function loadExamSummary() {
    try {
      console.log("Fetching exam summary")
      setBackdrop(true)
      const response = await fetch(`api/exam/summary?year=${props.selectedYear}`)
      if (response.ok) {
        const received: ExamSummaryReport[] = await response.json();
        setExamSummaryReports(received)
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        showErrorMessage(await response.text())
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  const action = (snackbarId: SnackbarKey | undefined) => (
    <>
      <button onClick={() => { closeSnackbar(snackbarId) }}>
        Dismiss
      </button>
    </>
  );

  function showErrorMessage(message: string) {
    console.log(message)
    enqueueSnackbar(message, {
      action,
      variant: 'error',
      persist: true,
      anchorOrigin: { vertical: 'top', horizontal: 'center' }
    })
  }

  useEffect(() => {
    loadExamSummary();
  }, [])

  return (
    <Stack height='100%'>
      <SBackdrop open={backdrop} />
      <SnackbarProvider />
      {loading &&
        <Stack gap={2} padding={2}>
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
        </Stack>
      }
      {!loading &&
        <Stack padding={2}>
          <SPaper>
            <SCard>
              <CardHeader
                title="Exam Summary"
                titleTypographyProps={{ variant: "body1", align: "center" }}
                sx={{ padding: 1 }}
              />
              <Divider />
              <SCardContent>
                <Grid container spacing={2} padding={1}>
                  <SCardRowTextColumn
                    label="Level"
                    labelColor='text.primary'
                    valueColor='text.primary'
                    value1="Term1"
                    value2="Term2"
                    value3="Term3"
                    sxValue={{ textAlign: 'center', fontWeight: 'bold' }}
                    sxLabel={{ fontWeight: 'bold' }}
                  />
                </Grid>
                <Divider sx={{ marginBottom: 2 }} />
                {examSummaryReports.map((examSummaryReport, index) =>
                  <Grid container spacing={2} padding={1} key={index}>
                    <SCardRowIcon label={examSummaryReport.level}
                      icon1={getTermIcon(examSummaryReport, 'Term1')}
                      icon2={getTermIcon(examSummaryReport, 'Term2')}
                      icon3={getTermIcon(examSummaryReport, 'Term3')}
                    />
                  </Grid>
                )}
              </SCardContent>
              <Divider />
            </SCard>
          </SPaper >
        </Stack>
      }
    </Stack>
  );

  function getTermIcon(examSummaryReport: ExamSummaryReport, term: string): JSX.Element {
    const termData = getTerm(examSummaryReport, term);
    return termData?.emailSent
      ? <MarkEmailReadIcon color='primary' />
      : (termData?.scoreAdded
        ? <SaveIcon color='secondary' />
        : <HourglassEmptyIcon color='disabled' />);
  }

  function getTerm(examSummaryReport: ExamSummaryReport, term: string) {
    return examSummaryReport.termData.find(termData => termData.term === term);
  }
}