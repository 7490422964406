import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { CSSProperties, Fragment } from 'react';

interface SCardRowTextColumnProps {
  label: string;
  value1?: string | number;
  value2?: string | number;
  value3?: string | number;
  labelColor?: string;
  valueColor?: string;
  column1Width?: number;
  column2Width?: number;
  column3Width?: number;
  column4Width?: number;
  sxLabel?: CSSProperties;
  sxValue?: CSSProperties;
}

export function SCardRowTextColumn({
  label,
  value1,
  value2,
  value3,
  labelColor = 'text.secondary',
  valueColor = 'text.secondary',
  column1Width = 6,
  column2Width = 2,
  column3Width = 2,
  column4Width = 2,
  sxLabel,
  sxValue,
}: SCardRowTextColumnProps) {
  return (
    <Fragment>
      <Grid xs={column1Width} padding={0}>
        <Typography variant="body1" color={labelColor} sx={{ ...(sxLabel) }}>
          {label}
        </Typography>
      </Grid>
      <Grid xs={column2Width} padding={0} sx={{ ...(sxValue) }}>
        <Typography variant="body1" color={valueColor} sx={{ ...(sxValue) }}>
          {value1}
        </Typography>
      </Grid>
      <Grid xs={column3Width} padding={0} sx={{ ...(sxValue) }}>
        <Typography variant="body1" color={valueColor} sx={{ ...(sxValue) }}>
          {value2}
        </Typography>
      </Grid>
      <Grid xs={column4Width} padding={0} sx={{ ...(sxValue) }}>
        <Typography variant="body1" color={valueColor} sx={{ ...(sxValue) }}>
          {value3}
        </Typography>
      </Grid>
    </Fragment>
  );
}