import { Stack } from '@mui/material';
import { FormEventHandler, ReactNode } from 'react';

interface SStackFormProps {
  children: ReactNode
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
}

export function SStackForm(props: SStackFormProps) {
  return (
    <Stack height='100%' component='form' onSubmit={props.onSubmit}>
      {props.children}
    </Stack>
  );
}