import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Backdrop, CircularProgress, Fab, Skeleton, Stack, TextField } from "@mui/material";
import { DateTime } from 'luxon';
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from 'react';
import { AccessLevel, UserInfo } from '../../components/AppConstants';
import { BookCard } from './BookCard';
import { EditBook } from "./EditBook";
import { BookLanguage, BookLevel, LibraryBook } from './LibraryDtos';
import { TextFieldOptional } from '../../components/custom/TextFieldOptional';

interface LibraryBooksProps {
  userInfo: UserInfo
  onFilterChange: (size: number) => void
  onBackClick: () => void
  logout: () => void
}

interface LibraryBookFilter {
  label: string
  group: string
}

const LibraryBookFilterOptions: LibraryBookFilter[] = [
  { label: 'ColorBook', group: 'Feature' },
  { label: 'ImportantLetters', group: 'Feature' },
  { label: 'BigLetters', group: 'Feature' },
  { label: BookLevel.Hard, group: 'Level' },
  { label: BookLevel.Medium, group: 'Level' },
  { label: BookLevel.Easy, group: 'Level' },
  { label: BookLevel.NA, group: 'Level' },
  { label: BookLanguage.Tamil, group: 'Language' },
  { label: BookLanguage.English, group: 'Language' },
];

export function LibraryBooks(props: LibraryBooksProps) {
  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [backdrop, setBackdrop] = useState(false);
  const [libraryBooks, setLibraryBooks] = useState<LibraryBook[]>([])
  const [filters, setFilters] = useState<LibraryBookFilter[]>([])
  const [filteredBooks, setFilteredBooks] = useState<LibraryBook[]>([])
  const [selectedBook, setSelectedBook] = useState<LibraryBook | undefined>(undefined)
  const [searchKeyword, setSearchKeyword] = useState<string>("")

  async function loadBooks() {
    try {
      const urlToLoad = "api/books";
      console.log(urlToLoad)
      setBackdrop(true)
      const response = await fetch(urlToLoad)
      if (response.ok) {
        const result: LibraryBook[] = await response.json();
        setLibraryBooks(result)
        setFilteredBooks(result)
        console.log("Exisitng book: " + selectedBook ? selectedBook?.id : undefined)
        if (selectedBook) {
          const existingBook = result.find((book) => book.id === selectedBook.id);
          console.log("From DB Exisitng book: " + selectedBook ? selectedBook?.id : undefined)
          setSelectedBook(existingBook)
        }
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No library books found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    loadBooks()
  }, [refresh])

  async function onDelete(libraryBook: LibraryBook): Promise<void> {
    try {
      setBackdrop(true)
      const response = await fetch("api/books/" + libraryBook.id, { method: 'DELETE' })
      if (response.ok) {
        console.log("Book deleted successfully.")
        setSelectedBook(undefined)
        setRefresh(!refresh)
        enqueueSnackbar('Book deleted successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Book deletion failed.")
        enqueueSnackbar('Book deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Book deletion failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function onBookUpdated(libraryBook: LibraryBook): Promise<void> {
    try {
      setBackdrop(true)
      const response = await fetch("api/books/" + libraryBook.id, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(libraryBook)
      })
      if (response.ok) {
        console.log("Book updated successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('Book updated successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Book update failed.")
        enqueueSnackbar('Book update failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Book update failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function onBookAdded(libraryBook: LibraryBook): Promise<void> {
    try {
      setBackdrop(true)
      const response = await fetch("api/books", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(libraryBook)
      })
      if (response.ok) {
        console.log("Book added successfully.")
        setRefresh(!refresh)
        enqueueSnackbar('Book added successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Adding Book failed.")
        enqueueSnackbar('Adding Book failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Adding Book failed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  useEffect(() => {
    const firstFiltered = libraryBooks.filter((libraryBook) => {
      let match = true
      for (const filter of filters) {
        if (filter.group === 'Level') {
          match = match && libraryBook.level === filter.label
        } else if (filter.group === 'Language') {
          match = match && libraryBook.language === filter.label
        } else if (filter.label === 'ColorBook') {
          match = match && libraryBook.colorBook
        } else if (filter.label === 'ImportantLetters') {
          match = match && libraryBook.importantLetters
        } else if (filter.label === 'BigLetters') {
          match = match && libraryBook.bigLetters
        }
      }
      return match
    })
    const secondFiltered = firstFiltered.filter((libraryBook) => {
      return libraryBook.bookName.toLowerCase().includes(searchKeyword.toLowerCase())
        || libraryBook.author.toLowerCase().includes(searchKeyword.toLowerCase())
    })
    setFilteredBooks(secondFiltered)
    props.onFilterChange(secondFiltered.length)
  }, [filters, searchKeyword])

  return (
    <Stack height='100%'>
      {/* This Backdrop extend the container to max screen height.
      To fix the BottomBar position issues in android browser due to auto url bar hiding */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading &&
        <Stack gap={1} height="100%">
          <Skeleton variant="rectangular" height={170} />
          <Skeleton variant="rectangular" height={170} />
          <Skeleton variant="rectangular" height={170} />
        </Stack>
      }
      <Stack>
        {!selectedBook &&
          <Stack height='100%' padding={2} gap={2}>
            <Autocomplete
              multiple
              id="library-books-name"
              options={LibraryBookFilterOptions}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              value={filters}
              onChange={(_event, newValue) => {
                setFilters(newValue === null ? [] : newValue)
              }}
              renderInput={(params) => <TextField {...params} label="Filter" />}
              renderOption={(props, option) => <li {...props} key={option.label}>{option.label}</li>}
            />
            <TextFieldOptional
              label="Search"
              placeholder='Search by title or author'
              value={searchKeyword}
              baseValue={searchKeyword}
              onChange={setSearchKeyword}
            />
            {filteredBooks.map((libraryBook, index) =>
              <BookCard key={index} userInfo={props.userInfo} index={index + 1} libraryBook={libraryBook} onSelect={book => setSelectedBook(book)} />
            )}
          </Stack>
        }
      </Stack>
      {!loading && !selectedBook && props.userInfo.libraryAccess === AccessLevel.ReadWrite &&
        <Fab color="secondary" sx={{ position: 'fixed', bottom: '16px', left: '50%', transform: 'translateX(-50%)' }} onClick={(_event) => {
          setSelectedBook({
            id: -1,
            bookName: "",
            author: "",
            copies: 1,
            language: BookLanguage.Tamil,
            level: BookLevel.NA,
            bigLetters: false,
            colorBook: false,
            importantLetters: false,
            feature: "",
            createdAt: DateTime.now().toUTC().toString(),
            createdBy: props.userInfo.name,
            updatedAt: DateTime.now().toUTC().toString(),
            updatedBy: props.userInfo.name,
          })
        }}>
          <AddIcon />
        </Fab>
      }

      {selectedBook &&
        <EditBook
          libraryBook={selectedBook}
          onBack={() => { setSelectedBook(undefined) }}
          onDelete={onDelete}
          onUpdated={book => book.id === -1 ? onBookAdded(book) : onBookUpdated(book)}
          logout={props.logout}
        />
      }
    </Stack>
  );
}