import { Stack } from "@mui/material";
import { SchoolDay, StudentDayStatus } from "./Dtos";
import { StudentSwitch } from "./StudentSwitch";

interface StudentSwitchListProps {
  selectedLevel: string
  selectedDay: SchoolDay
  selectedStatus: string
  studentDayStatuses: StudentDayStatus[]
  onStudentSwitchChange: (studentId: Number, isSelected: boolean) => void
}

export function StudentSwitchList(props: StudentSwitchListProps) {
  return (
    <Stack gap={2} >
      {props.studentDayStatuses.length > 0 &&
        props.studentDayStatuses.map((studentDayStatus, index) =>
          <StudentSwitch
            key={index}
            index={index + 1}
            selectedLevel={props.selectedLevel}
            selectedDay={props.selectedDay}
            selectedStatus={props.selectedStatus}
            studentDayStatus={studentDayStatus}
            onStudentSwitchChange={props.onStudentSwitchChange} />
        )
      }
    </Stack>
  );
}