import { TextField } from "@mui/material";
import { grey } from "@mui/material/colors";

interface NumberFieldProps {
  label: string
  value: number
  min?: number
  max?: number
  step?: number
  readonly?: boolean
  onChange?: (newValue: number) => void
}

export function NumberField({
  label,
  value,
  min = 0,
  max = 100,
  step = 1,
  readonly,
  onChange
}: NumberFieldProps) {
  return (
    <TextField
      required={!readonly}
      fullWidth
      size="small"
      type="number"
      label={label}
      value={value}
      color={(value > max || value < min) ? 'error' : 'primary'}
      sx={{ ...(readonly && { backgroundColor: grey[100] }) }}
      InputProps={{
        type: 'number',
        readOnly: readonly,
        inputProps: {
          shrink: "true",
          min: min,
          max: max,
          step: step,
          style: { textAlign: 'center' }
        }
      }}
      onChange={(event) => onChange && onChange(parseFloat(event.target.value))}
    />
  )
}