import { Stack } from '@mui/material';
import { ExamPage } from '../pages/exam/ExamPage';
import { LibraryPage } from '../pages/library/LibraryPage';
import { StudentPage } from '../pages/student/StudentPage';
import { StudentDayComponent } from '../pages/studentDay/StudentDayComponent';
import { UsersPage } from '../pages/users/UsersPage';
import { PageName, UserInfo } from './AppConstants';
import { ExamSetupPage } from '../pages/examSetup/ExamSetupPage';
import { AppLog } from '../pages/log/AppLog';
import { ExamSummaryPage } from '../pages/examSummary/ExamSummaryPage';
import { TeacherDayComponent } from '../pages/teacherDay/TeacherDayComponent';

interface ContentProps {
  userInfo: UserInfo
  selectedPage: PageName
  selectedYear: string
  selectedLevel: string
  logout: () => void
}

export function Content(props: ContentProps) {
  return (
    <Stack height='100%'>
      {props.selectedPage === PageName.Students &&
        <StudentPage userInfo={props.userInfo} selectedYear={props.selectedYear} selectedLevel={props.selectedLevel} logout={props.logout} />
      }
      {props.selectedPage === PageName.Attendance &&
        <StudentDayComponent urlPath='attendance' selectedYear={props.selectedYear} selectedLevel={props.selectedLevel} selectedStatus='Present' deselectedStatus='Absent' logout={props.logout} />
      }
      {props.selectedPage === PageName.Homework &&
        <StudentDayComponent urlPath='homework' selectedYear={props.selectedYear} selectedLevel={props.selectedLevel} selectedStatus='Completed' deselectedStatus='NotCompleted' logout={props.logout} />
      }
      {props.selectedPage === PageName.Library &&
        <LibraryPage userInfo={props.userInfo} selectedYear={props.selectedYear} logout={props.logout} />
      }
      {props.selectedPage === PageName.Exam &&
        <ExamPage selectedYear={props.selectedYear} selectedLevel={props.selectedLevel} logout={props.logout} />
      }
      {props.selectedPage === PageName.ExamSetup &&
        <ExamSetupPage selectedLevel={props.selectedLevel} logout={props.logout} />
      }
      {props.selectedPage === PageName.Users &&
        <UsersPage userInfo={props.userInfo} selectedLevel={props.selectedLevel} logout={props.logout} />
      }
      {props.selectedPage === PageName.Log &&
        <AppLog logout={props.logout} />
      }
      {props.selectedPage === PageName.ExamSummary &&
        <ExamSummaryPage selectedYear={props.selectedYear} logout={props.logout} />
      }
      {props.selectedPage === PageName.AllStudents &&
        <StudentPage userInfo={props.userInfo} selectedYear={props.selectedYear} logout={props.logout} />
      }
      {props.selectedPage === PageName.TeacherAttendance &&
        <TeacherDayComponent urlPath='attendance' selectedYear={props.selectedYear} selectedStatus='Present' deselectedStatus='Absent' logout={props.logout} />
      }
    </Stack>
  );
}