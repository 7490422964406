import { Stack } from '@mui/material';
import { SCardContent } from '../../components/styled/card/SCardContent';
import { SCardRowText } from '../../components/styled/card/SCardRowText';
import { SPaper } from '../../components/styled/paper/SPaper';
import { LibraryStats } from './LibraryDtos';

interface LibraryStatsPageProps {
  libraryStats: LibraryStats
}

export function LibraryStatsPage(props: LibraryStatsPageProps) {

  return (
    <SPaper>
      <SCardContent>
        <Stack gap={1} paddingLeft={1} paddingRight={1}>
          <SCardRowText label="Total books" value={props.libraryStats.libraryBooksCount} />
          <SCardRowText label="Transacted books" value={props.libraryStats.transactedBooksCount} />
          <SCardRowText label="Checkedout books" value={props.libraryStats.checkedOutBooksCount} />
        </Stack>
      </SCardContent>
    </SPaper>
  );
}
