import { FormControlLabel, Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AndroidSwitch } from "../../components/custom/AndroidSwitch";
import { SchoolDay, StudentDayStatus } from "./Dtos";
import { SPaper } from "../../components/styled/paper/SPaper";

interface StudentSwitchProps {
  index: number
  selectedLevel: string
  selectedDay: SchoolDay
  selectedStatus: string
  studentDayStatus: StudentDayStatus
  onStudentSwitchChange: (studentId: Number, isSelected: boolean) => void
}

export function StudentSwitch(props: StudentSwitchProps) {
  return (
    <SPaper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1}>
        <Typography variant="h6" color="text.secondary">{props.index}. {props.studentDayStatus.studentName}</Typography>
        <FormControlLabel sx={{ margin: 0 }}
          control={<AndroidSwitch studentId={props.studentDayStatus.studentId} isSelected={props.studentDayStatus.status === props.selectedStatus} onChange={props.onStudentSwitchChange} />}
          label=""
        />
      </Stack>
    </SPaper>
  );
}