import { BottomNavigationAction } from '@mui/material';
import { ReactNode } from 'react';

interface BottomNavActionProps {
  label: string
  icon: ReactNode
  loading?: boolean
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void
}

export function BottomNavAction(props: BottomNavActionProps) {
  return (
    <BottomNavigationAction
      showLabel
      disabled={props.loading}
      label={props.label}
      icon={props.icon}
      type={props?.type}
      onClick={props?.onClick}
      sx={{ ...(!props.loading && { color: "primary.main", "&:hover": { color: "secondary.main" } }) }} />
  );
}
